import * as actionTypes from '../actions/actionTypes.js';
import { updateObject } from '../utils.js';

const initialState = {
    token: null,
    userId: null,
    role: null,
    error: null,
    loading: false,
    authRedirectPath: '/anime',
    recovery: null,
    email: null,
    success: false,
};

const authStart = (state, action) => {
    return updateObject(state, {loading: true, error: null, success:false});
}

const ajaxLoading = (state, action) => {
    return updateObject(state, {loading: true, error: null, success:false});
}

const unreadMessages = (state, action) => {
    return updateObject(state, {unreadMessages: action.count});
}


const authSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        token: action.token,
        userId: action.userId,
        role: action.role,
        design: action.design,
        username: action.username,
        email: null,
        recovery: false,
        success: true
    });
}

const passwordRecoveryRequested = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        recovery: true,
        email: action.email
    });
}


const passwordUpdated = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        token: action.token
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        userId: null,
        token: null,
        success: false
    });
}

const ajaxFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        success: false
    });
}


const authLogout = (state, action) => {
    return updateObject(state, {
        token: null,
        userId: null,
        username: null,
        design: 'white',
        role: null,
        error: null,
        loading: false,
        authRedirectPath: '/',
        success: false
    });
}

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {authRedirectPath:action.path});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_AJAX_FAIL: return ajaxFail(state, action);
        case actionTypes.AUTH_AJAX_START: return ajaxLoading(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.AUTH_PASSWORD_UPDATED: return passwordUpdated(state, action);
        case actionTypes.AUTH_PASSWORD_RECOVERY_SENT: return passwordRecoveryRequested(state, action);
        case actionTypes.AUTH_UNREAD_MESSAGES: return unreadMessages(state, action);
        default: return state;
    }
}

export default reducer;
