import React from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import axios from '../../axios-admin';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});


class List extends React.Component {
    
    state = {
        list: [],
        search: '',
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('anime-recommendations?limit=50')
        .then(res => {
            this.setState({list:res.data.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    toggleApprove(e, id) {
        axios.post(`anime-recommendations/${id}/approve`)
        .then(res => {
            const updatedList = this.state.list.map(obj => {
                if(obj.id === id){
                    obj.evaluated = !obj.evaluated;
                }
                return obj;
            });
            this.setState({list:updatedList});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    deleteRecommendation(e, id) {
        axios.delete(`anime-recommendations/${id}`)
        .then(res => {
            const filtered = this.state.list.filter(obj => obj.id !== id);
            this.setState({list:filtered});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    

  render() {
    const { classes } = this.props;
    
    const rows = this.state.list.map(obj => {
        let status = <Chip color="default" label="InActive" />;
        if(obj.evaluated){
            status = <Chip color="primary" label="Active" />;
        }
        
      return (
        <TableRow key={obj.id}>
          <TableCell component="th" scope="row"><Link to={'/anime/' + obj.id}>{obj.user.username}</Link></TableCell>
          <TableCell>{obj.created_at.substring(0, 16).replace('T', ' ')}</TableCell>
          <TableCell>{obj.anime.name}</TableCell>
          <TableCell>{obj.recommendation.name}</TableCell>
          <TableCell><Button onClick={e => this.toggleApprove(e, obj.id)}>{status}</Button></TableCell>
          <TableCell>{obj.comment}</TableCell>
          <TableCell><Button onClick={e => this.deleteRecommendation(e, obj.id) }>X</Button></TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper className={classes.root}>
            <h3>Anime Recommendations</h3>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Anime</TableCell>
                <TableCell>Recomm</TableCell>
                <TableCell>Approved</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
       </Paper>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);