import React from 'react';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';

const onlineListTable = props => {
    let rows = null;
    if(props.data){
        rows = props.data.map(obj => {

            let format = obj.type_format;
            if(obj.videoType){
                format = obj.videoType;
            }

            return (
                <TableRow key={obj.id}>
                <TableCell padding="dense"><Link to={'/online/' + obj.id}>{obj.anime.name}</Link></TableCell>
                <TableCell padding="dense"><Link to={'/online/' + obj.id}>{obj.source}</Link></TableCell>
                <TableCell padding="dense">{obj.typeTxt}</TableCell>
                <TableCell padding="dense">{obj.ep}</TableCell>
                <TableCell padding="dense">{format}</TableCell>
                <TableCell padding="dense">
                    <Moment>
                        {obj.display_from_time.date}
                    </Moment>
                </TableCell>
                </TableRow>
            );
        })
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell padding="dense">Name</TableCell>
                    <TableCell padding="dense">Source</TableCell>
                    <TableCell padding="dense">Type</TableCell>
                    <TableCell padding="dense">Ep</TableCell>
                    <TableCell padding="dense">Format</TableCell>
                    <TableCell padding="dense">Time</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
         </Table>
    )
}

export default onlineListTable;