import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import ArticleForm from './ArticleForm.js';
import axios from '../../axios-admin.js';
import {Redirect} from 'react-router-dom';

class ArticleNew extends Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }
    
    state = {
        success: false,
        data: null
    }

    onSuccess(data){
        
       axios.post('articles', data)
        .then(res => {
            console.log('update on success', res.data);
            if(res.data.message === 'success'){
                this.setState({success: true, data: res.data.data});
            }
        })
        .catch(err => {
            console.log('create fail', err);
        });
    }
    
    render() {
        let redirect = null;
        if(this.state.success){
            redirect = <Redirect to={'/articles/' + this.state.data.id} />;
        }
        
        return (<Paper>{redirect}<ArticleForm success={this.onSuccess} /></Paper>)
    }   
}

export default ArticleNew;