import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index.js';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { checkValidity } from '../../shared/utility.js';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class Login extends Component {
    
    state = {
        controls: {
            email: {
                value: '',
                validation: {
                    required: true,
                    minLength: 5,
                    isEmail: true
                },
                valid: false,
                touched: false,
            },
            password: {
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                },
                valid: false,
                touched: false,
            },
        },
        isLogin: true,
        formIsValid: false
    }
    
    inputChangedHandler = (event) => {
        const inputIdentifier = event.target.id;
        
        const updatedControls = {
            ...this.state.controls,
            [inputIdentifier]: {
                ...this.state.controls[inputIdentifier],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[inputIdentifier].validation),
                touched: true
            }
        };

        let formIsValid = true;
        for(let inputIdentifier in updatedControls){
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControls, formIsValid: formIsValid});
    }

    submitHandler = (event) => {
        event.preventDefault();
        if(!this.state.formIsValid){
            const updatedControls = {
                ...this.state.controls
            };
            for (let formElementIdentifier in this.state.controls) {
                updatedControls[formElementIdentifier] = {
                  ...this.state.controls[formElementIdentifier],
                  touched: true
                };
            }
            this.setState({controls: updatedControls});
            return ;
        }
        this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value);
    }
    
  render() {
      const { classes } = this.props;

      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} onSubmit={this.submitHandler}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input 
                    id="email" 
                    name="email" 
                    autoComplete="email" 
                    value={this.state.controls.email.value}
                    onChange={(event) => this.inputChangedHandler(event) }
                    autoFocus />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input 
                    name="password" 
                    type="password" 
                    id="password" 
                    value={this.state.controls.password.value}
                    onChange={(event) => this.inputChangedHandler(event) }
                    autoComplete="current-password" />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign in
              </Button>
            </form>
          </Paper>
        </main>
      );
  }

  
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, pass) => dispatch(actions.auth(email, pass, true)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));