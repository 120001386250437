import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import axios from '../../axios-admin.js';


class Projects extends React.Component {
    
    state = {
        list: [],
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('projects')
        .then(res => {
            this.setState({list:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    deleteBtn(id){
        axios.delete('projects/' + id)
        .then(res => {
            this.fetchData();
        })
    }

  render() {
    
    const rows = this.state.list.map(obj => {

      return (
        <TableRow key={obj.id}>
          <TableCell padding="dense">{obj.anime.name}</TableCell>
          <TableCell padding="dense">{obj.translators}</TableCell>
          <TableCell padding="dense">{obj.editors}</TableCell>
          <TableCell padding="dense">{obj.type}</TableCell>
          <TableCell padding="dense"><Button id={obj.id} onClick={event => this.deleteBtn(obj.id)}>X</Button></TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper>
         <h2>Projects</h2>
         <Link to="/projects/new">New Project</Link>
         <Table>
           <TableHead>
             <TableRow>
               <TableCell padding="dense">Anime</TableCell>
               <TableCell padding="dense">Translator</TableCell>
               <TableCell padding="dense">Editor</TableCell>
               <TableCell padding="dense">Type</TableCell>
               <TableCell padding="dense"></TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {rows}
           </TableBody>
         </Table>
       </Paper>
    );
  }
}

export default Projects;