import React from 'react';
import { checkValidity } from '../../shared/utility.js';
import ImageUploadResizer from 'react-form-upload-image-resize';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import InputLabel from '@material-ui/core/InputLabel';

class ArticleForm extends React.Component {
    
    state = {
        anime: [],
        controls: {
            image: {
                value : (this.props.data) ? this.props.data.image_url : '',
                validation: {
                    required: true,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            title: {
                value : (this.props.data) ? this.props.data.title : '',
                validation: {
                    required: true,
                    minLength: 2,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            status: {
                value : (this.props.data) ? this.props.data.status : false,
                valid: true,
                touched: true,
            },
            bbcode: {
                value : (this.props.data) ? this.props.data.bbcode : false,
                valid: true,
                touched: true,
            },
            shortDescription: {
                value : (this.props.data) ? this.props.data.shortDescription : '',
                validation: {
                    required: true
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            description: {
                value : (this.props.data) ? this.props.data.description : '',
                validation: {
                    required: true
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            updated_at: {
                value : (this.props.data) ? this.props.data.modified_at : "2018-12-24T10:30",
                valid: true,
                touched: true,
            },
        },
        formIsValid: (this.props.data) ? true : false,
    }

    imageUploadHandler = (base64) => {

        const inputIdentifier = 'image';

        const updatedControl = {
            ...this.state.controls
        }

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        }

        updatedControlElement.value = base64;
        updatedControlElement.valid = true;
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    switchHandler = event => {
        const inputIdentifier = event.target.id;
        
        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = !updatedControlElement.value;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    selectChangedHandler = (option, inputIdentifier) => {

        const updatedControl = {
            ...this.state.controls
        };

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        updatedControlElement.value = option.value;
        updatedControlElement.valid = checkValidity(option.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        updatedControl[inputIdentifier] = updatedControlElement;

        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControl, formIsValid: formIsValid});

    }
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }
  
        this.props.success(formData);
    }
    
    
  render() {
      
    let image = null;
    if(this.state.controls.image.value !== ''){
        image = <img src={this.state.controls.image.value} alt="article" style={{width: "100%"}} />
    }
      

    return (
        <form noValidate onSubmit={e => this.submitHandler(e) }>
            <Grid container spacing={16} direction="row" justify="center">
                <Grid item md={7} sm={6}>
                    {image}
                </Grid>
                
                <Grid item md={5} sm={6}>
                    <Grid container spacing={8}>
                    
                        <Grid item md={12}>
                            <ImageUploadResizer id="image" name="image" change={this.imageUploadHandler}  width={900} height={300} />
                        </Grid>
                        <Grid item md={12}>
                            <InputLabel>Title</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.title.value}
                                id="title" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.title.valid && this.state.controls.title.touched} />
                        </Grid>
                        <Grid item sm={6}>
                        <FormControlLabel
                              control={
                                <Switch value="status" id="status" checked={this.state.controls.status.value} onChange={this.switchHandler}  />
                              }
                              label="Active"
                            />
                        </Grid>
                        <Grid item sm={6}>
                        <FormControlLabel
                              control={
                                <Switch value="bbcode" id="bbcode" checked={this.state.controls.bbcode.value} onChange={this.switchHandler}  />
                              }
                              label="BBcode"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                id="updated_at"
                                label="Added On"
                                onChange={this.inputChangedHandler}
                                value={this.state.controls.updated_at.value}
                                type="datetime-local"
                                InputLabelProps={{shrink: true}}
                              />
                        </Grid>
                        
                    </Grid>
                    
                    
                </Grid>

                <Grid item sm={12}>
                    <TextField 
                        fullWidth 
                        multiline 
                        label="ShortDescription"
                        name="ShortDescription" 
                        id="shortDescription" 
                        required 
                        rows="5" 
                        onChange={this.inputChangedHandler}
                        value={this.state.controls.shortDescription.value}  />
                </Grid>
                
                <Grid item sm={12}>
                    <TextField 
                        fullWidth 
                        multiline 
                        label="Description"
                        name="description" 
                        id="description" 
                        required 
                        rows="10" 
                        onChange={this.inputChangedHandler}
                        value={this.state.controls.description.value}  />
                </Grid>
                
                <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
            </Grid>
        </form>
    );
  }
}

export default ArticleForm;