import React from 'react';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

const onlineListTable = props => {
    let rows = null;
    if(props.data){
        rows = props.data.map(obj => {
            let status = <Chip color="default" label="InActive" />;
            if(obj.status){
                status = <Chip color="primary" label="Active" />;
            }
 
            return (
                <TableRow key={obj.id}>
                <TableCell padding="dense" component="th" scope="row"><Link to={'/anime/' + obj.anime.id}>{obj.anime.name}</Link></TableCell>
                <TableCell padding="dense">{obj.server}</TableCell>
                <TableCell padding="dense"><Link to={'/online/' + obj.id}>{obj.source}</Link></TableCell>
                <TableCell padding="dense">{obj.typeTxt}</TableCell>
                <TableCell padding="dense">{obj.ep}</TableCell>
                <TableCell padding="dense">{status}</TableCell>
                </TableRow>
            );
        })
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                <TableCell padding="dense">Name</TableCell>
                <TableCell padding="dense">Server</TableCell>
                <TableCell padding="dense">Source</TableCell>
                <TableCell padding="dense">Type</TableCell>
                <TableCell padding="dense">Ep</TableCell>
                <TableCell padding="dense">Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
         </Table>
    )
}

export default onlineListTable;