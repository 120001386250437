import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import OnlineForm from './OnlineForm';
import axios from '../../axios-admin';
import Grid from '@material-ui/core/Grid';
import AnimeTranslatorForm from '../Anime/AnimeTranslatorForm';

class OnlineView extends Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }
    
    state = {
        success: false,
        data: null
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;
        
        axios.get('online/' + id)
        .then(res => {
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    onSuccess(data){
        const url = 'online/' + this.props.match.params.id;
        
        axios.post(url, data)
        .then(res => {
            console.log('update on success', res.data);
            if(res.data.message === 'success'){
                this.setState({success: true});
            }
        })
        .catch(err => {
            console.log('create fail', err);
        });
    }
    
    render() {
        let notice = null;
        if(this.state.success){
            notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Update OK</div>;
        }
        let form = null;
        if(this.state.data){
            form = <OnlineForm data={this.state.data} success={this.onSuccess} />;
        }

        let form2 = null;
        if(this.state.data){
            form2 = <AnimeTranslatorForm type="online" data={this.state.data} />;
        }
        
        return (
            <Paper>
                {notice}
                <Grid container spacing={8}>
                    <Grid item sm={9}>
                        <h3>Online:</h3>
                        {form}
                    </Grid>
                    <Grid item sm={3}>
                        <h3>Translators:</h3>
                        {form2}
                    </Grid>
                </Grid>
            </Paper>
        )
    }   
}

export default OnlineView;