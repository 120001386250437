import React from 'react';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from '../../axios-admin';


class FlowView extends React.Component {
    
    state = {
        list: [],
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('online/flow')
        .then(res => {
            this.setState({list:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    deleteBtn(id){
        axios.delete('projects/' + id)
        .then(res => {
            this.fetchData();
        })
    }

  render() {
    
    const rows = this.state.list.map(obj => {

      return (
        <TableRow key={obj.id}>
            <TableCell padding="dense"><Link to={`/users/${obj.user.id}`}>{obj.user.username}</Link></TableCell>
            <TableCell padding="dense">{obj.online.anime.name}</TableCell>
            <TableCell padding="dense">{obj.online.ep}</TableCell>
            <TableCell padding="dense">{obj.expire}</TableCell>
            <TableCell padding="dense">{obj.ip}</TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper>
         <h2>Srautas</h2>
         <Table>
           <TableHead>
             <TableRow>
               <TableCell padding="dense">Username</TableCell>
               <TableCell padding="dense">Anime</TableCell>
               <TableCell padding="dense">Ep</TableCell>
               <TableCell padding="dense">Logged</TableCell>
               <TableCell padding="dense">Ip</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {rows}
           </TableBody>
         </Table>
       </Paper>
    );
  }
}

export default FlowView;