export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_AJAX_START = 'AUTH_AJAX_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PASSWORD_UPDATED = 'AUTH_PASSWORD_UPDATED';
export const AUTH_PASSWORD_RECOVERY_SENT = 'AUTH_PASSWORD_RECOVERY_SENT';
export const AUTH_AJAX_FAIL = 'AUTH_AJAX_FAIL';
export const AUTH_UNREAD_MESSAGES = 'AUTH_UNREAD_MESSAGES';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const ARTICLES_AJAX_LOADING = 'ARTICLES_AJAX_LOADING';
export const ARTICLES_AJAX_FAIL = 'ARTICLES_AJAX_FAIL';
export const ARTICLES_FETCH_LIST_SUCCESS = 'ARTICLES_FETCH_LIST_SUCCESS';
export const ARTICLES_FETCH_SINGLE_SUCCESS = 'ARTICLES_FETCH_SINGLE_SUCCESS';

export const ONLINE_AJAX_LOADING = 'ONLINE_AJAX_LOADING';
export const ONLINE_AJAX_FAIL = 'ONLINE_AJAX_FAIL';
export const ONLINE_FETCH_LIST_SUCCESS = 'ONLINE_FETCH_LIST_SUCCESS';
export const ONLINE_FETCH_SINGLE_SUCCESS = 'ONLINE_FETCH_SINGLE_SUCCESS';

export const COMMENTS_AJAX_LOADING = 'COMMENTS_AJAX_LOADING';
export const COMMENTS_AJAX_FAIL = 'COMMENTS_AJAX_FAIL';
export const COMMENTS_FETCH_LIST_SUCCESS = 'COMMENTS_FETCH_LIST_SUCCESS';
export const COMMENTS_FETCH_SINGLE_SUCCESS = 'COMMENTS_FETCH_SINGLE_SUCCESS';

export const ANIME_AJAX_LOADING = 'ANIME_AJAX_LOADING';
export const ANIME_AJAX_FAIL = 'ANIME_AJAX_FAIL';
export const ANIME_FETCH_LIST_SUCCESS = 'ANIME_FETCH_LIST_SUCCESS';
export const ANIME_FETCH_LIST_SUCCESS_SILENT = 'ANIME_FETCH_LIST_SUCCESS_SILENT';
export const ANIME_FETCH_SINGLE_SUCCESS = 'ANIME_FETCH_SINGLE_SUCCESS';
export const ANIME_FETCH_SINGLE_ONLINE_LIST_SUCCESS = 'ANIME_FETCH_SINGLE_ONLINE_LIST_SUCCESS';
export const ANIME_FETCH_GENRES_LIST = 'ANIME_FETCH_GENRES_LIST';
export const ANIME_FETCH_SEARCH_LIST = 'ANIME_FETCH_SEARCH_LIST';

export const MANGA_AJAX_LOADING = 'MANGA_AJAX_LOADING';
export const MANGA_AJAX_FAIL = 'MANGA_AJAX_FAIL';
export const MANGA_FETCH_LIST_SUCCESS = 'MANGA_FETCH_LIST_SUCCESS';
export const MANGA_FETCH_SINGLE_SUCCESS = 'MANGA_FETCH_SINGLE_SUCCESS';

export const CMS_AJAX_LOADING = 'CMS_AJAX_LOADING';
export const CMS_AJAX_FAIL = 'CMS_AJAX_FAIL';
export const CMS_FETCH_LIST = 'CMS_FETCH_LIST';
export const CMS_FETCH_SINGLE = 'CMS_FETCH_SINGLE';