import React from 'react';

import { Link } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from '../../axios-admin';

class FingerprintsList extends React.Component {
    
    state = {
        list: [],
        page: 1,
        user_id: '',
        ip: '',
        fingerprint: '',
        cid: '',
        isp: '',
        ip_country: '',
    }
    
    componentDidMount() {
        this.fetchData(1);
    }
    
    fetchData(page){
        const query = [];
        if(this.state.user_id !== ''){
            query.push('user_id=' + parseInt(this.state.user_id, 10));
        }
        if(this.state.ip !== ''){
            query.push('ip=' + this.state.ip);
        }
        if(this.state.cid !== ''){
            query.push('cid=' + this.state.cid);
        }
        if(this.state.isp !== ''){
            query.push('isp=' + this.state.isp);
        }
        if(this.state.ip_country !== ''){
            query.push('ip_country=' + this.state.ip_country);
        }
        if(this.state.fingerprint !== '' && this.state.fingerprint.length === 32){
            query.push('fingerprint=' + this.state.fingerprint);
        }

        axios.get('users/fingerprints?page=' + page + '&' + query.join('&'))
        .then(res => {
            this.setState({list:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    inputChangedHandler(event){
         const o = {};
         o[event.target.id] = event.target.value;
         this.setState(o);
         
    }

    componentDidUpdate(prevProps, prevState) {

        if (
            this.state.user_id !== prevState.user_id || 
            this.state.ip !== prevState.ip || 
            this.state.cid !== prevState.cid || 
            this.state.isp !== prevState.isp || 
            this.state.ip_country !== prevState.ip_country || 
            (this.state.fingerprint !== prevState.fingerprint && this.state.fingerprint.length === 32)
        ) {
            this.fetchData(1);
        }
        
    }

  render() {
    
    const rows = this.state.list.map(obj => {

        let user = null;
        if(obj.user){
            user = <Link to={'/users/' + obj.user.id}>{obj.user.username  + ' (' + obj.user_id + ')'}</Link>;
        }
        
        return (
            <TableRow key={obj.id}>
                <TableCell style={{padding: "3px", fontSize: "10px"}} component="th" scope="row">{user}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.ip}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.location}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.user_agent}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.created_at.substring(0, 16).replace('T', ' ')}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.fingerprint}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.cid}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.ip_country}</TableCell>
                <TableCell style={{padding: "3px", fontSize: "10px"}}>{obj.isp}</TableCell>
            </TableRow>
        );
    })
    
    return (
        <Paper style={{width: "100%", overflowX:"auto"}}>
            <h3>Fingerprints List</h3>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>User</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>Ip</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>Location</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>Agent</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>Logged</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>Fingerprint</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>Google ID</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>Country</TableCell>
                        <TableCell style={{padding: "3px", fontSize: "10px"}}>ISP</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="user_id" 
                                name="user_id" 
                                value={this.state.user_id}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="ip" 
                                name="ip" 
                                value={this.state.ip}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="location" 
                                name="location" 
                                value={this.state.location}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="user_agent" 
                                name="user_agent"
                                value={this.state.user_agent}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                        <TableCell style={{padding: "3px"}}></TableCell>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="fingerprint" 
                                name="fingerprint"
                                value={this.state.fingerprint}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="cid" 
                                name="cid" 
                                value={this.state.cid}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="ip_country" 
                                name="ip_country" 
                                value={this.state.ip_country}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                        <TableCell style={{padding: "3px"}}>
                            <Input 
                                styles={{fontSize: "12px"}}
                                id="isp" 
                                name="isp"
                                value={this.state.isp}
                                onChange={(event) => this.inputChangedHandler(event) } />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </Paper>
    );
  }
}

export default FingerprintsList;