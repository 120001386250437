import React, { Component } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';

import DefaultLayout from './hoc/Layout/DefaultLayout';

import Login from './containers/Login/Login';
import Logout from './containers/Auth/Logout/Logout';

import OnlineList from './containers/Online/List';
import OnlineNew  from './containers/Online/New';
import OnlineView from './containers/Online/View';
import OnlinePlans from './containers/Online/Plans';

import AnimeList from './containers/Anime/List';
import AnimeView from './containers/Anime/View';
import AnimeNew  from './containers/Anime/New';

import ArticlesList from './containers/Articles/List';
import ArticlesView from './containers/Articles/View';
import ArticlesNew from './containers/Articles/New';

import MangaList from './containers/Manga/List';
import MangaView from './containers/Manga/View';
import MangaNew from './containers/Manga/New';
import MangaChapterView from './containers/Manga/ViewChapter';

import UsersList from './containers/Users/List';
import UsersView from './containers/Users/View';

import Donation  from './containers/Donation/Donation';
import JoinTeam from './containers/JoinTeam/JoinTeam';
import Projects from './containers/Projects/Projects';
import ProjectsNew from './containers/Projects/ProjectsNew';
import FlowView from './containers/FlowView/FlowView';

import PollList from './containers/Poll/List';
import PollNew from './containers/Poll/New';
import PollEdit from './containers/Poll/Edit';

import FingerprintsList from './containers/Fingerprints/List';
import Blacklist from './containers/Blacklist/List';
import StudiosView from './containers/Studios/View';

import AnimeRecommendations from './containers/AnimeRecommendations/List';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'

library.add(faLock);
library.add(faEnvelope);
library.add(faUser);

class App extends Component {
    componentDidMount(){
        this.props.onTryAutoAuth();
    }
    
    render() {
        const userRoutes = [];

        userRoutes.push(<Route key={0} path="/" exact name="anime" component={AnimeList} />);
        userRoutes.push(<Route key={31} path="/anime" exact name="anime" component={AnimeList} />);
        userRoutes.push(<Route key={32} path="/anime/new" exact name="anime" component={AnimeNew} />);
        userRoutes.push(<Route key={33} path="/anime/:id" exact name="anime view" component={AnimeView} />);
        userRoutes.push(<Route key={34} path="/anime-recommendations" exact name="anime recommendations" component={AnimeRecommendations} />);
        userRoutes.push(<Route key={4} path="/online" exact name="online" component={OnlineList} />);
        userRoutes.push(<Route key={5} path="/online/new" exact name="online new" component={OnlineNew} />);
        userRoutes.push(<Route key={6} path="/online/:id" exact name="online view" component={OnlineView} />);
        userRoutes.push(<Route key={'6a'} path="/plans" exact name="online plans" component={OnlinePlans} />);
        userRoutes.push(<Route key={7} path="/articles" exact name="articles" component={ArticlesList} />);
        userRoutes.push(<Route key={8} path="/articles/new" exact name="articles new" component={ArticlesNew} />);
        userRoutes.push(<Route key={9} path="/articles/:id" exact name="articles view" component={ArticlesView} />);
        userRoutes.push(<Route key={10} path="/donation" exact name="donation" component={Donation} />);
        userRoutes.push(<Route key={11} path="/join-team" exact name="join team" component={JoinTeam} />);
        userRoutes.push(<Route key={12} path="/projects" exact name="projects" component={Projects} />);
        userRoutes.push(<Route key={13} path="/projects/new" exact name="projects new" component={ProjectsNew} />);
        userRoutes.push(<Route key={14} path="/users" exact name="users list" component={UsersList} />);
        userRoutes.push(<Route key={15} path="/users/:id" exact name="users view" component={UsersView} />);
        userRoutes.push(<Route key={16} path="/fingerprints" exact name="fingerprints list" component={FingerprintsList} />);
        userRoutes.push(<Route key={17} path="/studios" exact name="studios list" component={StudiosView} />);
        userRoutes.push(<Route key={20} path="/polls" exact name="poll list" component={PollList} />);
        userRoutes.push(<Route key={21} path="/polls/new" exact name="poll new" component={PollNew} />);
        userRoutes.push(<Route key={22} path="/polls/:id" exact name="poll edit" component={PollEdit} />);
        userRoutes.push(<Route key={81} path="/manga" exact name="manga list" component={MangaList} />);
        userRoutes.push(<Route key={82} path="/manga/new" exact name="manga new" component={MangaNew} />);
        userRoutes.push(<Route key={83} path="/manga/chapter/:id" exact name="manga chapter view" component={MangaChapterView} />);
        userRoutes.push(<Route key={84} path="/manga/:id" exact name="manga view" component={MangaView} />);
        userRoutes.push(<Route key={85} path="/srautas" exact name="flow" component={FlowView} />);
        userRoutes.push(<Route key={86} path="/blacklist" exact name="flow" component={Blacklist} />);
        userRoutes.push(<Route key={91} path="/logout" exact name="Logout" component={Logout} />);
        userRoutes.push(<Route key={90} path="/login" exact component={Login} />);

        return (
            <div>
                <DefaultLayout isAuth={this.props.isAuth}>
                    <Switch>
                        {userRoutes}
                    </Switch>
                </DefaultLayout>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoAuth: () => dispatch(actions.authCheckState())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
