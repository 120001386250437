import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import AnimeForm from './AnimeForm';
import AnimeTranslatorForm from './AnimeTranslatorForm';
import AnimeRelationshipsForm from './AnimeRelationshipsForm';
import axios from '../../axios-admin';
import Grid from '@material-ui/core/Grid';
import OnlineListTable from '../Online/OnlineListTable';

class AnimeView extends Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }
    
    state = {
        success: false,
        data: null,
        episodes: null
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;
        
        axios.get('anime/' + id + '?with=online,online.anime')
        .then(res => {
            console.log(res.data.data);
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    onSuccess(data){
        
        const url = 'anime/' + this.props.match.params.id;
        
        axios.post(url, data)
        .then(res => {
            console.log('update on success', res.data);
            if(res.data.message === 'success'){
                this.setState({success: true});
            }
        })
        .catch(err => {
            console.log('create fail', err);
        });
    }
    
    render() {
        let notice = null;
        if(this.state.success){
            notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Update OK</div>;
        }
        let form = null;
        if(this.state.data){
            form = <AnimeForm data={this.state.data} success={this.onSuccess} />;
        }

        let form2 = null;
        if(this.state.data){
            form2 = <AnimeTranslatorForm type="anime" data={this.state.data} />;
        }

        let form3 = null;
        if(this.state.data){
            form3 = <AnimeRelationshipsForm data={this.state.data} />;
        }

        let online = null;
        if(this.state.data){
            online = this.state.data.online;
        }

        let video = null;
        if(this.state.data && this.state.data.youtube){
            video = <iframe src={this.state.data.youtube.replace("watch?v=", "embed/")} style={{width:"100%", height: "auto"}} frameBorder="0" allowFullScreen />
        }
        
        return (
            <Paper>
                {notice}
                <Grid container spacing={8}>
                    <Grid item sm={9}>
                        <h3>Anime:</h3>
                        {form}
                    </Grid>
                    <Grid item sm={3}>
                        {video}
                        <h3>Translators:</h3>
                        {form2}

                        <h3 style={{marginTop:"32px"}}>Relationships:</h3>
                        {form3}
                    </Grid>
                </Grid>
                <Grid container spacing={8}>
                    <OnlineListTable data={online} />
                </Grid>
            
            </Paper>
        )
    }   
}

export default AnimeView;