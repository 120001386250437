import React from 'react';

import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';

import axios from '../../axios-admin.js';

class Donation extends React.Component {
    
    state = {
        data: null,
        success: false
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('donation')
        .then(res => {
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }

        const updatedData = {
            ...this.state.data
        };
        
        updatedData[inputIdentifier] = event.target.value;
        
        this.setState({data: updatedData});
    }

    submitHandler(event){
        event.preventDefault();

        const data = {
            target: parseInt(this.state.data.target, 10),
            current: parseInt(this.state.data.current, 10),
            deadline: this.state.data.deadline,
        }

        axios.post('donation', data)
        .then(res => {
            this.setState({success: true});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

  render() {

    if(!this.state.data){
        return <Paper><h3>Donation</h3></Paper>
    }

    let notice = null;
    if(this.state.success){
        notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Update OK</div>;
    }
    
    return (
        <Paper>
            <h3>Donation</h3>
            {notice}
            <form noValidate onSubmit={e => this.submitHandler(e) }>
                <Grid container spacing={16} direction="row">
                    <Grid item sm={3}>
                        <InputLabel>Target</InputLabel>
                        <Input 
                        id="target" 
                        name="target"
                        type="number" 
                        value={this.state.data.target}
                        onChange={(event) => this.inputChangedHandler(event) } />
                    </Grid>
                    <Grid item sm={3}>
                        <InputLabel for="current">current</InputLabel>
                        <Input 
                            id="current" 
                            label="current"
                            name="current"
                            type="number" 
                            value={this.state.data.current}
                            onChange={(event) => this.inputChangedHandler(event) } />
                    </Grid>

                    <Grid item sm={4}>
                        <TextField
                            id="deadline"
                            label="deadline"
                            onChange={this.inputChangedHandler}
                            value={this.state.data.deadline.substr(0, 10)}
                            type="date"
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                    </Grid>
                </Grid>
            </form>
            
       </Paper>
    );
  }
}

export default Donation;