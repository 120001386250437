import React from 'react';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import axios from '../../axios-admin.js';


class JoinTeam extends React.Component {
    
    state = {
        list: [],
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('join-team')
        .then(res => {
            this.setState({list:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    deleteBtn(id){
        axios.delete('join-team/' + id)
        .then(res => {
            this.fetchData();
        })
    }

  render() {
    
    const rows = this.state.list.map(obj => {
      let username = null;
      if(obj.user){
        username = obj.user.username;
      }
      return (
        <TableRow key={obj.id}>
          <TableCell padding="dense">{username}</TableCell>
          <TableCell padding="dense">{obj.name}</TableCell>
          <TableCell padding="dense">{obj.position}</TableCell>
          <TableCell padding="dense">{obj.email}</TableCell>
          <TableCell padding="dense">{obj.discord}</TableCell>
          <TableCell padding="none">{obj.user_id}</TableCell>
          <TableCell padding="dense">{obj.message}</TableCell>
          <TableCell padding="dense">{obj.created_at.substring(0, 16).replace('T', ' ')}</TableCell>
          <TableCell padding="dense"><Button id={obj.id} onClick={event => this.deleteBtn(obj.id)}>X</Button></TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper>
          <h2>Join Team</h2>
         <Table>
           <TableHead>
             <TableRow>
               <TableCell padding="dense">Username (webe)</TableCell>
               <TableCell padding="dense">Name</TableCell>
               <TableCell padding="dense">Position</TableCell>
               <TableCell padding="dense">Email</TableCell>
               <TableCell padding="dense">Discord</TableCell>
               <TableCell padding="none">User</TableCell>
               <TableCell padding="dense">Message</TableCell>
               <TableCell padding="dense">Created</TableCell>
               <TableCell padding="dense"></TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {rows}
           </TableBody>
         </Table>
       </Paper>
    );
  }
}

export default JoinTeam;