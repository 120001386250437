export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const time = () => {
  return new Date();
}

export const isInt = (value) => {
  return !isNaN(value) &&
         parseInt(Number(value), 10) === value &&
         !isNaN(parseInt(value, 10));
}

export const hasNumber = (check) => {
  return /\d/.test(check);
}

export const hasAlphaNumeric = (check) => {
  return /^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/.test(check);
}

export const hasAlphabet = (check) => {
  return /[a-zA-Z]/.test(check);
}

export const hasUpperLetter = (check) => {
  return /[A-Z]/.test(check);
}

export const hasLowerLetter = (check) => {
  return /[a-z]/.test(check);
}

export const checkValidity = ( value, rules ) => {

    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if(rules.required){
        if(!isInt(value)){
            isValid = value.trim() !== '' && isValid;
        }

    } else if(!rules.required && false === rules.required && value.trim() === ''){
        return true;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid;
    }
    
    if ( rules.min ) {
        isValid = value >= rules.min && isValid;
    }
    
    if ( rules.max ) {
        isValid = value <= rules.max && isValid;
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.containsDigits ){
        isValid = hasNumber(value) && isValid;
    }

    if ( rules.containsAlphabet ){
        isValid = hasAlphabet(value) && isValid;
    }

    if ( rules.containsUpperLetter ) {
        isValid = hasUpperLetter(value) && isValid;
    }

    if ( rules.containsLowerLetter ) {
        isValid = hasLowerLetter(value) && isValid;
    }

    return isValid;
}

export const validateControlsForm = (controls) => {
    const updatedControls = {
      ...controls
    };
    let formElementIdentifier = '';
    for (formElementIdentifier in controls) {
      updatedControls[formElementIdentifier] = {
        ...controls[formElementIdentifier],
        touched: true
      };
    }
  
    return updatedControls;
  };
  
export const formInputChangedHandler = (controls, inputIdentifier, newValue) => {
    const updatedControl = {
      ...controls
    };
  
    const updatedControlElement = {
      ...updatedControl[inputIdentifier]
    };
    updatedControlElement.value = newValue;
    updatedControlElement.valid = checkValidity(newValue, updatedControlElement.validation);
    updatedControlElement.touched = true;
    updatedControl[inputIdentifier] = updatedControlElement;
  
    let formIsValid = true;
    let field = '';
    for (field in updatedControl) {
      formIsValid = updatedControl[field].valid && formIsValid;
    }
  
    return { controls: updatedControl, formIsValid };
};