import React from 'react';

import { Link } from 'react-router-dom';

import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import axios from '../../axios-admin';


class ArticlesList extends React.Component {
    
    state = {
        articles: [],
        list: [],
        search: '',
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('articles?limit=1000')
        .then(res => {
            this.setState({list:res.data.data, articles:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    inputChangedHandler(event){
        
        const newList = this.state.articles.filter(obj => obj.title.toLowerCase().includes(event.target.value.toLowerCase()) );
        
        this.setState({search: event.target.value, list: newList });
    }

  render() {
    
    const rows = this.state.list.map(obj => {
        let status = <Chip color="default" label="InActive" />;
        let bbcode = <Chip color="default" label="InActive" />;
        if(obj.status){
            status = <Chip color="primary" label="Active" />;
        }
        
        if(obj.bbcode){
            bbcode = <Chip color="primary" label="Active" />;
        }
        
      return (
        <TableRow key={obj.id}>
          <TableCell component="th" scope="row"><Link to={'/articles/' + obj.id}>{obj.title}</Link></TableCell>
          <TableCell>{obj.modified_at.substring(0, 16).replace('T', ' ')}</TableCell>
          <TableCell>{status}</TableCell>
          <TableCell>{bbcode}</TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper>
          <Link to="/articles/new">New Article</Link>
          <Input 
              id="title" 
              name="title" 
              autoComplete="title" 
              value={this.state.search}
              onChange={(event) => this.inputChangedHandler(event) } />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>BBCode</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
       </Paper>
    );
  }
}

export default ArticlesList;