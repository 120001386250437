import React from 'react';

import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import axios from '../../axios-admin';

class PollList extends React.Component {
    
    state = {
        articles: [],
        list: [],
        search: '',
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('polls?limit=1000')
        .then(res => {
            this.setState({list:res.data.data, articles:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    inputChangedHandler(event){
        
        const newList = this.state.articles.filter(obj => obj.title.toLowerCase().includes(event.target.value.toLowerCase()) );
        
        this.setState({search: event.target.value, list: newList });
    }

  render() {
    
    const rows = this.state.list.map(obj => {
        let status = <Chip color="default" label="InActive" />;
        if(obj.status){
            status = <Chip color="primary" label="Active" />;
        }
        
        return (
            <TableRow key={obj.id}>
                <TableCell component="th" scope="row"><Link to={'/polls/' + obj.id}>{obj.name}</Link></TableCell>
                <TableCell>{obj.options_count}</TableCell>
                <TableCell>{obj.startdate.slice(0, 10)}</TableCell>
                <TableCell>{obj.enddate.slice(0, 10)}</TableCell>
                <TableCell>{obj.display_to_date.slice(0, 10)}</TableCell>
                <TableCell>{status}</TableCell>
            </TableRow>
        );
    })
    
    return (
        <Paper>
          <Link to="/polls/new">New Poll</Link>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Options</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Vote Until</TableCell>
                <TableCell>Display Until</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
       </Paper>
    );
  }
}

export default PollList;