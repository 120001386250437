import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import OnlineListTable from './OnlineListTable';
import axios from '../../axios-admin';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

class OnlineList extends React.Component {
    
    state = {
        data: null,
        search: '',
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    componentDidUpdate(prevProps, prevState) {
      // Typical usage (don't forget to compare props):
      if (this.state.search !== prevState.search) {
        this.fetchData();
      }
    }

    
    fetchData(){
        let url = 'online';
        if(this.state.search.length > 3){
            url += '?name='+this.state.search;
        }
        axios.get(url)
        .then(res => {
            this.setState({data:res.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    inputChangedHandler(event){
        this.setState({search: event.target.value});
    }

  render() {
    const { classes } = this.props;
    
    return (
        <Paper className={classes.root}>
          <Link to="/online/new">New Online</Link>
          <Input 
              id="name" 
              name="name" 
              autoComplete="name" 
              value={this.state.search}
              onChange={(event) => this.inputChangedHandler(event) } />
          <OnlineListTable data={this.state.data} />
       </Paper>
    );
  }
}

OnlineList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OnlineList);