import React from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';


import axios from '../../axios-admin.js';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});


class MangaList extends React.Component {
    
    state = {
        manga: [],
        list: [],
        search: '',
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('manga?limit=1000')
        .then(res => {
            this.setState({list:res.data.data, manga:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    inputChangedHandler(event){
        
        const newList = this.state.manga.filter(obj => obj.name.toLowerCase().includes(event.target.value.toLowerCase()) );
        
        this.setState({search: event.target.value, list: newList });
    }

  render() {
    const { classes } = this.props;
    
    const rows = this.state.list.map(obj => {
        let status = <Chip color="default" label="InActive" />;
        let bbcode = <Chip color="default" label="InActive" />;
        if(obj.status){
            status = <Chip color="primary" label="Active" />;
        }
        
        if(obj.bbcode){
            bbcode = <Chip color="primary" label="Active" />;
        }
        
      return (
        <TableRow key={obj.id}>
          <TableCell component="th" scope="row"><Link to={'/manga/' + obj.id}>{obj.title}</Link></TableCell>
          <TableCell>{obj.created_at ? obj.created_at.slice(0, 10) : ''}</TableCell>
          <TableCell>{obj.type}</TableCell>
          <TableCell>{status}</TableCell>
          <TableCell>{bbcode}</TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper className={classes.root}>
          <Link to="/manga/new">New Manga</Link>
          <Input 
              id="name" 
              name="name" 
              autoComplete="name" 
              value={this.state.search}
              onChange={(event) => this.inputChangedHandler(event) } />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>BBCode</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
       </Paper>
    );
  }
}

MangaList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MangaList);