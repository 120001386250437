import React from 'react';
import { checkValidity } from '../../shared/utility';

import { Player } from 'video-react';
import "video-react/dist/video-react.css"; // import css

import Grid from '@material-ui/core/Grid';

import AsyncSelect from 'react-select/lib/Async';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import InputLabel from '@material-ui/core/InputLabel';
import NoSsr from '@material-ui/core/NoSsr';

import { time } from '../../shared/utility';

import axios from '../../axios-admin';

class OnlineForm extends React.Component {
    
    state = {
        anime: [],
        controls: {
            anime: {
                value : (this.props.data) ? this.props.data.anime_id : 0,
                selected: [],
                validation: {
                    required: true,
                    min: 1
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            server: {
                value : (this.props.data) ? this.props.data.server : '',
                selected: [],
                validation: {
                },
                valid: true,
                touched: (this.props.data) ? true : false,
            },
            src: {
                value : (this.props.data) ? this.props.data.source : '',
                validation: {
                    required: true,
                    minLength: 10,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            type: {
                value : (this.props.data) ? this.props.data.type : 0,
                valid: true,
                touched: true,
            },
            ep: {
                value : (this.props.data) ? this.props.data.ep : '',
                validation: {
                    required: true,
                    minLength: 1,
                    isInt: true
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            active: {
                value : true,
                valid: true,
                touched: true,
            },
            display_time: {
                value : (this.props.data) ? this.props.data.display_time : time().toISOString().substring(0, 19),
                valid: true,
                touched: true,
            },
            display_from_time: {
                value : (this.props.data) ? this.props.data.display_from_time : time().toISOString().substring(0, 19),
                valid: true,
                touched: true,
            }
        },
        formIsValid: (this.props.data) ? true : false,
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    switchHandler = event => {
        const inputIdentifier = event.target.id;
        
        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = !updatedControlElement.value;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    loadOptions = (inputValue, callback) => {
        
        if(this.state.anime.length > 1){
            const filteredData = this.state.anime.filter(obj => obj.label.toLowerCase().includes(inputValue.toLowerCase()) );
            callback(filteredData);
            return ;
        }
        
        axios.get('options/anime')
        .then(res => {
            callback(res.data.data);
            
            let elem = [];
            if(this.props.data){
                elem = res.data.data.find(el => this.props.data.anime_id === el.value);
            }
            
            const updatedControl = {
                ...this.state.controls
            };
            
            const updatedControlElement = {
                ...updatedControl['anime']
            };
            
            updatedControlElement.selected  = elem;
            updatedControl['anime'] = updatedControlElement;
            
            this.setState({selected:elem, controls: updatedControl, anime: res.data.data});
        })
        .catch(err => {
            console.log('load fail', err);
        });
    };
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }
        
        const data = {
            anime_id     : formData.anime,
            source       : formData.src,
            ep           : parseInt(formData.ep, 10),
            status       : formData.active,
            type         : formData.type,
            video_format : 'mp4',
            server       : formData.server || null,
            display_time : formData.display_time.replace('T', ' '),
            display_from_time: formData.display_from_time.replace('T', ' '),
        };
        
        this.props.success(data);
    }
    
    
  render() {
      const optionChange = (option) => {
          const updatedControl = {
              ...this.state.controls
          };
          
          const updatedControlElement = {
              ...updatedControl['anime']
          };
          
          updatedControlElement.value = option.value;
          updatedControlElement.selected = option;
          updatedControlElement.valid = true;
          updatedControlElement.touched = true;
          updatedControl['anime'] = updatedControlElement;
          
          this.setState({controls:updatedControl});
      }
    
    return (
        <Grid container spacing={8}>
            <Grid item sm={5}>
                <Player ref="player" playsInline preload="metadata" src={'https://s1.amf.lt/' + this.state.controls.src.value} />
            </Grid>
            <Grid item sm={7}>
                <form noValidate onSubmit={e => this.submitHandler(e) }>
                    <Grid container direction="column" spacing={0}>
                        <Grid item sm={12}>
                            <NoSsr>
                            <InputLabel htmlFor="age-native-simple">Anime</InputLabel>
                            <AsyncSelect 
                                isSearchable 
                                cacheOptions 
                                onChange={optionChange} 
                                defaultOptions 
                                loadOptions={this.loadOptions} 
                                value={this.state.controls.anime.selected}
                                placeholder="Type in anime name" />
                            </NoSsr>
                        </Grid>
                        
                        <Grid item sm={12}>
                            <InputLabel>Source</InputLabel>
                            <Input 
                                fullWidth 
                                id="src"
                                placeholder="Type in anime video source"
                                value={this.state.controls.src.value}
                                error={!this.state.controls.src.valid && this.state.controls.src.touched}
                                onChange={this.inputChangedHandler} />
                        </Grid>
                        
                        <Grid item>
                            <Grid container spacing={8}>
                                <Grid item sm={3}>
                                    <InputLabel>Type</InputLabel>
                                    <Select value={this.state.controls.type.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'type'}}>
                                        <MenuItem value={0}>Subs</MenuItem>
                                        <MenuItem value={1}>Audio</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item sm={3}>
                                    <InputLabel>server</InputLabel>
                                    <Select value={this.state.controls.server.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'server'}}>
                                        <MenuItem value={0}>Random</MenuItem> 
                                        <MenuItem value={"s1"}>S1</MenuItem>
                                        <MenuItem value={"s2"}>S2</MenuItem>
                                        <MenuItem value={"s3"}>S3</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item sm={3}>
                                    <InputLabel>Ep</InputLabel>
                                    <Input 
                                        fullWidth 
                                        value={this.state.controls.ep.value}
                                        id="ep" 
                                        onChange={this.inputChangedHandler} 
                                        type="number"
                                        error={!this.state.controls.ep.valid && this.state.controls.ep.touched} />
                                </Grid>
                                <Grid item sm={3}>
                                <FormControlLabel
                                      control={
                                        <Switch value="active" id="active" checked={this.state.controls.active.value} onChange={this.switchHandler}  />
                                      }
                                      label="Active"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        label="Display to time"
                                        id="display_time"
                                        onChange={this.inputChangedHandler}
                                        value={this.state.controls.display_time.value}
                                        type="datetime-local"
                                        InputLabelProps={{shrink: true}}
                                        style={{width: "100%"}}
                                      />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        label="Active From time"
                                        id="display_from_time"
                                        onChange={this.inputChangedHandler}
                                        value={this.state.controls.display_from_time.value}
                                        type="datetime-local"
                                        InputLabelProps={{shrink: true}}
                                        style={{width: "100%"}}
                                      />
                                </Grid>
                                
                                <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            
       </Grid>
    );
  }
}

export default OnlineForm;