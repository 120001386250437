import * as actionTypes from './actionTypes.js';
import axios from '../../axios-admin.js';
import jwtDecode from 'jwt-decode';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const ajaxLoading = () => {
    return {
        type: actionTypes.AUTH_AJAX_START
    }
}

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userId: jwtDecode(token).sub,
        role: jwtDecode(token).role,
        username: jwtDecode(token).username,
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const ajaxFail = (error) => {
    return {
        type: actionTypes.AUTH_AJAX_FAIL,
        error: error
    }
}

export const auth = (email, password, isSignUp) => {
    return dispatch => {
        dispatch(authStart());

        const authData = {
            email: email,
            password: password
        };

        axios.post('auth/login', authData)
        .then(response => {
            const token = response.data.data;
            
            const expirationDate = new Date(jwtDecode(token).exp * 1000); // bloody miliseconds
            const now = new Date();
            const expiresIn = Math.round((expirationDate.getTime() - now.getTime()) / 1000);

            localStorage.setItem('token', token);
            localStorage.setItem('expirationDate', expirationDate);
            dispatch(authSuccess(token));
            dispatch(checkOffTimeout(expiresIn));
        })
        .catch(err => {
            console.log(err.response);
            if(err.response){
              dispatch(authFail(err.response.data.message));
            }else{
              dispatch(authFail('Connection Error'));
            }
        });
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkOffTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        
        if(token){
            const decoded = jwtDecode(token);
            const now = new Date();
            if(now.getTime() < decoded.exp * 1000){
                dispatch(authSuccess(token));
                
                const expirationDate = new Date(decoded.exp * 1000); // bloody miliseconds
                const expiresIn = Math.round((expirationDate.getTime() - now.getTime()) / 1000);
                dispatch(checkOffTimeout(expiresIn));
            }
        }
    }
}