import React from 'react';
import { checkValidity } from '../../shared/utility';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AnimeSelect from '../../components/UI/Form/AnimeSelect';
import axios from '../../axios-admin.js';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';

class AnimeRelationshipsForm extends React.Component {
    
    state = {
        controls: {
            anime_id: {
                value : '',
                validation: {
                    required: true,
                    isInt: true
                },
                valid: false,
                touched: false,
            },
            type: {
                value : '',
                valid: false,
                touched: false,
            }
        },
        formIsValid: false,
        relationships: []
    }

    componentDidMount() {
        this.fetchRelationships();
    }

    fetchRelationships(){
        axios.get('anime/' + this.props.data.id + '/relationships')
        .then(res => {
            this.setState({relationships:res.data.data});
        })
        .catch(err => {
            console.log(err.response.data);
        });
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    selectChangedHandler = (option, inputIdentifier) => {

        const updatedControl = {
            ...this.state.controls
        };

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        updatedControlElement.value = option.value;
        updatedControlElement.valid = checkValidity(option.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        updatedControl[inputIdentifier] = updatedControlElement;

        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControl, formIsValid: formIsValid});

    }
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }

        axios.post('anime/' + this.props.data.id + '/relationships', formData)
        .then(res => {
            this.fetchRelationships();
        })
        .catch(err => {
            console.log(err.response.data);
        });
    }

    removeTranslator(parent_id, child_id){
        axios.delete('anime/' + parent_id + '/relationships/' + child_id)
        .then(res => {
            const updatedTranslators = this.state.relationships.filter(relationship => {
                return relationship.parent_anime !== parent_id && relationship.child_anime !== child_id;
            });
            this.setState({relationships:updatedTranslators});
        })
        .catch(err => {
            console.log(err);
        });
    }
    
    
  render() {


    return (
        <form noValidate onSubmit={e => this.submitHandler(e) }>
            <Grid container spacing={16} direction="row" justify="center">
                
                <Grid item sm={12}>
                    <AnimeSelect 
                        selected={this.state.controls.anime_id.value} 
                        change={(option) => this.selectChangedHandler(option, 'anime_id')} />
                </Grid>
                <Grid item sm={12}>
                    <InputLabel>Type</InputLabel>
                    <Select value={this.state.controls.type.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'type'}}>
                        <MenuItem value="">-- --</MenuItem>
                        <MenuItem value="sequel">Tęsinys</MenuItem>
                        <MenuItem value="prequel">Priešistorė</MenuItem>
                        <MenuItem value="alternative">Alternatyva</MenuItem>
                        <MenuItem value="summary">Santrauka</MenuItem>
                        <MenuItem value="full-story">Full Story</MenuItem>
                        <MenuItem value="main-story">main story</MenuItem>
                        <MenuItem value="spin-off">Spin-off</MenuItem>
                        <MenuItem value="side-story">Side story</MenuItem>
                    </Select>
                </Grid>
                
                <Grid item sm={12}>
                    <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                </Grid>

                <Grid item sm={12}>
                    <Table style={{fontSize:"0.8em"}}>
                    <TableBody>
                        {this.state.relationships.map((relationship, index) => {
                            if(relationship.parent_anime === this.props.data.id){
                                return (
                                    <TableRow key={index}>
                                        <TableCell padding="none">{relationship.child.name}</TableCell>
                                        <TableCell padding="none">{relationship.parent_title}</TableCell>
                                        <TableCell padding="none">
                                            <div onClick={event => this.removeTranslator(relationship.parent_anime, relationship.child_anime)}>X</div>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            return (
                                <TableRow key={index}>
                                    <TableCell padding="none">{relationship.parent.name}</TableCell>
                                    <TableCell padding="none">{relationship.child_title}</TableCell>
                                    <TableCell padding="none">
                                        <div onClick={event => this.removeTranslator(relationship.parent_anime, relationship.child_anime)}>X</div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </form>
    );
  }
}

export default AnimeRelationshipsForm;