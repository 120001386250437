import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import axios from '../../axios-admin.js';


class View extends React.Component {

    state = {
        list: [],
        search: null,
        title: ''
    }
    
    componentDidMount() {
        axios.get('studios')
        .then(res => {
            this.setState({list:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

    submitHandler(event) {
        event.preventDefault();
        console.log('submitted')

        if(this.state.title.length > 2){
            axios.post('studios', {title: this.state.title})
            .then(res => {
                console.log(res.data.data);
                const updatedList = [...this.state.list];
                updatedList.push(res.data.data);
            })
        }
    }

    inputChangedHandler(event){
        const val = event.target.value;
        this.setState({title:val});
    }
    
  render() {
    
    const rows = this.state.list.map(obj => {
        
      return (
        <TableRow key={obj.id}>
          <TableCell padding="dense">{obj.title}</TableCell>
          <TableCell padding="dense">{obj.slugifyUrl}</TableCell>
          <TableCell padding="dense"><Button>X</Button></TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper style={{width: "100%", overflowX:"auto"}}>
            <h3>Studios List</h3>

            <form onSubmit={e => this.submitHandler(e) }>
                <Grid container spacing={8} direction="row">
                    
                        <Grid item md={3}>
                            <InputLabel>New Studio</InputLabel>
                            <Input 
                                fullWidth 
                                onChange={event => this.inputChangedHandler(event)} 
                                value={this.state.title}
                                id="title" />
                        </Grid>
                        <Grid item sm={2}>
                            <Button style={{marginTop: "20px"}} variant="contained" type="submit" color="primary">Submit</Button>
                        </Grid>
                    
                </Grid>
            </form>
            <Table >
                <TableHead>
                    <TableRow>
                    <TableCell padding="dense">Title</TableCell>
                    <TableCell padding="dense">Slugify</TableCell>
                    <TableCell padding="dense"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
       </Paper>
    );
  }
}

export default View;