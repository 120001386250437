import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import OnlineForm from './OnlineForm';
import axios from '../../axios-admin';

class OnlineNew extends Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }
    
    onSuccess(data){
        
        axios.post('online', data)
        .then(res => {
            if(res.data.message === 'success'){
                this.props.history.push('/online/' + res.data.data.id);
            }
        })
        .catch(err => {
            console.log('create fail', err);
        });
        
    }
    
    render() {
        return (
            <Paper>
                <OnlineForm new success={this.onSuccess} />
            </Paper>
        )
    }   
}

export default OnlineNew;