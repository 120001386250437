import React from 'react';

import { Link } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import axios from '../../axios-admin.js';

class UsersList extends React.Component {
    
    state = {
      fullList: [],
      list: [],
      search: '',
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('users?limit=200')
        .then(res => {
            this.setState({fullList:res.data.data, list:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    inputChangedHandler(event){
        const newList = this.state.fullList.filter(obj => obj.username.toLowerCase().includes(event.target.value.toLowerCase()) );
        this.setState({search: event.target.value, list: newList });
    }

  render() {
    
    const rows = this.state.list.map(obj => {
        let restricted = <Chip color="default" label="No" />;

        if(obj.restricted){
            restricted = <Chip color="secondary" label="Yes" />;
        }
        
      return (
        <TableRow key={obj.id}>
          <TableCell padding="dense" component="th" scope="row"><Link to={'/users/' + obj.id}>{obj.username}</Link></TableCell>
          <TableCell padding="dense" component="th" scope="row"><Link to={'/users/' + obj.id}>{obj.email}</Link></TableCell>
          <TableCell padding="dense">{obj.role}</TableCell>
          <TableCell padding="dense">{obj.last_visit}</TableCell>
          <TableCell padding="dense">{obj.ban_until}</TableCell>
          <TableCell padding="dense">{restricted}</TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper style={{width: "100%", overflowX:"auto"}}>
            <h3>Users List</h3>
         <Input 
            id="name" 
            name="name" 
            autoComplete="name" 
            value={this.state.search}
            onChange={(event) => this.inputChangedHandler(event) } />
         <Table >
           <TableHead>
             <TableRow>
               <TableCell padding="dense">Name</TableCell>
               <TableCell padding="dense">Email</TableCell>
               <TableCell padding="dense">Role</TableCell>
               <TableCell padding="dense">Last Visit</TableCell>
               <TableCell padding="dense">Banned Unti</TableCell>
               <TableCell padding="dense">Restricted</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {rows}
           </TableBody>
         </Table>
       </Paper>
    );
  }
}

export default UsersList;