import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from '../../axios-admin';


class Blacklist extends React.Component {
    
    state = {
        list: [],
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('blacklist/hits')
        .then(res => {
            this.setState({list:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

  render() {
    
    const rows = this.state.list.map(obj => {
      let org = '';
      if (obj.isp) {
        org = obj.isp.org;
      }
      return (
        <TableRow key={obj.id}>
            <TableCell padding="dense">{obj.created_at}</TableCell>
            <TableCell padding="dense">{obj.ip}</TableCell>
            <TableCell padding="dense">{org}</TableCell>
            <TableCell padding="dense">{obj.ref}</TableCell>
            <TableCell padding="dense">{obj.user_agent}</TableCell>
        </TableRow>
      );
    })
    
    return (
        <Paper>
         <h2>Blacklist Hits</h2>
         <Table>
           <TableHead>
             <TableRow>
               <TableCell padding="dense">Time</TableCell>
               <TableCell padding="dense">IP</TableCell>
               <TableCell padding="dense">ORG</TableCell>
               <TableCell padding="dense">Ref</TableCell>
               <TableCell padding="dense">Agent</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {rows}
           </TableBody>
         </Table>
       </Paper>
    );
  }
}

export default Blacklist;