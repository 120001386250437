import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import axios from '../../axios-admin.js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

class MangaView extends Component {

    constructor(props) {
        super(props);
        this.handlePageUp = this.handlePageUp.bind(this);
        this.handlePageDown = this.handlePageDown.bind(this);
    }
    
    state = {
        success: false,
        data: null
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;
        
        axios.get('manga/chapter/' + id)
        .then(res => {
            console.log(res.data.data)
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    changeDirection(page, direction){
        const url = 'manga/chapter/' + this.props.match.params.id + '/page/' + page + '/' + direction;
        axios.post(url)
        .then(res => {
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log('create fail', err);
        });
    }

    handlePageUp(event, page){
        event.preventDefault();
        this.changeDirection(page, 'up');
    }

    handlePageDown(event, page){
        event.preventDefault();
        this.changeDirection(page, 'down');
    }
    
    render() {
        let notice = null;
        if(this.state.success){
            notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Update OK</div>;
        }

        let pages = [];
        if(this.state.data){
            pages = this.state.data.pages.map(page => {

                let upPage = null;
                if(page.page > 1){
                    upPage = (
                        <Button 
                            variant="contained" 
                            fullWidth 
                            onClick={event => this.handlePageUp(event, page.id)} 
                            type="submit" 
                            color="primary">
                            up
                        </Button>
                    );
                }
                let downPage = null;
                if(this.state.data.pages.length > page.page){
                    downPage = (
                        <Button 
                            variant="contained" 
                            fullWidth 
                            onClick={event => this.handlePageDown(event, page.id)} 
                            type="submit" 
                            color="secondary">
                            down
                        </Button>
                    );
                }

                return (
                    <Grid key={page.id} item sm={3}>
                        <p>Page: {page.page}</p>
                        <Grid container spacing={8}>
                            <Grid item sm={4}>
                                {upPage}
                            </Grid>
                            <Grid item sm={4}>
                                {downPage}
                            </Grid>
                        </Grid>
                        <img 
                            src={"http://api.amf.test/uploads/manga/" + this.state.data.manga_id + "/" + this.state.data.chapter + "/" + page.filename}
                            style={{maxWidth: "200px"}}
                            alt="manga"
                            title={"page " + page.id} />
                    </Grid>
                );
            })
        }

        return (
            <Paper>
                {notice}
                <Grid container spacing={8}>
                    <Grid item sm={12}>
                        <h3>Pages:</h3>
                    </Grid>
                    {pages}
                </Grid>
            </Paper>
        )
    }   
}

export default MangaView;