import React, {Component} from 'react';
import Select from 'react-select';

const options = [
    { value: 'Action', label: 'Action' },
    { value: 'Adventure', label: 'Adventure' },
    { value: 'Comedy', label: 'Comedy' },
    { value: 'Drama', label: 'Drama' },
    { value: 'Ecchi', label: 'Ecchi' },
    { value: 'Fantasy', label: 'Fantasy' },
    { value: 'Game', label: 'Game' },
    { value: 'Harem', label: 'Harem' },
    { value: 'Hentai', label: 'Hentai' },
    { value: 'Historical', label: 'Historical' },
    { value: 'Horror', label: 'Horror' },
    { value: 'Magic', label: 'Magic' },
    { value: 'Martial Arts', label: 'Martial Arts' },
    { value: 'Mecha', label: 'Mecha' },
    { value: 'Mystery', label: 'Mystery' },
    { value: 'Military', label: 'Military' },
    { value: 'Josei', label: 'Josei' },
    { value: 'Music', label: 'Music' },
    { value: 'Parody', label: 'Parody' },
    { value: 'Police', label: 'Police' },
    { value: 'Psychological', label: 'Psychological' },
    { value: 'Romance', label: 'Romance' },
    { value: 'Shoujo', label: 'Shoujo' },
    { value: 'Shounen', label: 'Shounen' },
    { value: 'Seinen', label: 'Seinen' },
    { value: 'Swordplay', label: 'Swordplay' },
    { value: 'Samurai', label: 'Samurai' },
    { value: 'School', label: 'School' },
    { value: 'Space', label: 'Space' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Super Power', label: 'Super Power' },
    { value: 'Slice of Life', label: 'Slice of Life' },
    { value: 'Supernatural', label: 'Supernatural' },
    { value: 'SciFi', label: 'SciFi' },
    { value: 'Tragedy', label: 'Tragedy' },
    { value: 'Thriller', label: 'Thriller' },
    { value: 'Vampire', label: 'Vampire' },
    { value: 'Voilence', label: 'Voilence' },
    { value: 'Isekai', label: 'Isekai' },
];

class GenreSelect extends Component {
    
    render() {
        const optionChange = (value) => {
            this.props.change({value: value.map(el => el.value)});
        }
        
        return (
            <Select
              defaultValue={options.filter(opt => this.props.selected.indexOf(opt.value) > -1 )}
              isMulti
              name="genres"
              onChange={optionChange}
              options={options}
            />
        )
    }
}

export default GenreSelect;