import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import ArticleForm from './ArticleForm.js';
import axios from '../../axios-admin.js';

class AnimeView extends Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }
    
    state = {
        success: false,
        data: null
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;
        
        axios.get('articles/' + id)
        .then(res => {
            console.log(res.data.data);
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    onSuccess(data){
        
        const url = 'articles/' + this.props.match.params.id;
        
        axios.post(url, data)
        .then(res => {
            console.log('update on success', res.data);
            if(res.data.message === 'success'){
                this.setState({success: true});
            }
        })
        .catch(err => {
            console.log('update fail', err);
        });
    }
    
    render() {
        let notice = null;
        if(this.state.success){
            notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Update OK</div>;
        }
        let form = null;
        if(this.state.data){
            form = <ArticleForm data={this.state.data} success={this.onSuccess} />;
        }
        
        return (<Paper>{notice}{form}</Paper>)
    }   
}

export default AnimeView;