import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import MangaForm from './MangaForm.js';
import axios from '../../axios-admin.js';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';

class MangaView extends Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }
    
    state = {
        success: false,
        data: null
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;
        
        axios.get('manga/' + id)
        .then(res => {
            console.log(res.data.data)
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    onSuccess(data){
        
        const url = 'manga/' + this.props.match.params.id;
        
        axios.post(url, data)
        .then(res => {
            console.log('update on success', res.data);
            if(res.data.message === 'success'){
                this.setState({success: true});
            }
        })
        .catch(err => {
            console.log('create fail', err);
        });
    }
    
    render() {
        let notice = null;
        if(this.state.success){
            notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Update OK</div>;
        }
        let form = null;
        if(this.state.data){
            form = <MangaForm data={this.state.data} success={this.onSuccess} />;
        }

        let chapters = [];
        if(this.state.data && this.state.data.chaptersList.length > 0){
            chapters = this.state.data.chaptersList.map(chapter => {
                return (
                    <TableRow key={chapter.id}>
                        <TableCell component="th" scope="row"><Link to={'/manga/chapter/' + chapter.id}>{chapter.chapter}</Link></TableCell>
                        <TableCell>{chapter.pages.length}</TableCell>
                        <TableCell>{chapter.title}</TableCell>
                    </TableRow>
                );
            });
        }

        return (
            <Paper>
                {notice}
                <Grid container spacing={8}>
                    <Grid item sm={12}>
                        <h3>Manga:</h3>
                        {form}
                    </Grid>

                    <Grid item sm={12}>
                        <h3>Chapters:</h3>
                        <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Chapter</TableCell>
                                <TableCell>Pages</TableCell>
                                <TableCell>Title</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chapters}
                        </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
        )
    }   
}

export default MangaView;