import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import axios from '../../../axios-admin.js';

class AnimeSelect extends Component {
    state = {
          selected: [],
          options: []
    };
    
    loadOptions = (inputValue, callback) => {
        if(this.state.options.length > 0){
            const options =  this.state.options.filter(el => el.label.toLowerCase().includes(inputValue.toLowerCase()));
            callback(options);
            return ;
        }

        axios.get('/options/anime')
        .then(response => {
            callback(response.data.data);
            let elem = [];
        
            if(this.props.value){
                elem = response.data.data.filter(el => this.props.value.indexOf(el.value) > -1);
            }
            this.setState({selected:elem, options: response.data.data});
        })
        .catch(err => {
            console.log('load fail', err);
        });
    };
      
    render () {
        
        const optionChange = (option) => {
            this.props.change({value: option.value});
            this.setState({selected:option});
        }
        
        return (
            <AsyncSelect
                cacheOptions
                loadOptions={this.loadOptions}
                onChange={optionChange}
                value={this.state.selected}
                defaultOptions />
        )
    }
}

export default AnimeSelect;