import React from 'react';
import { checkValidity } from '../../shared/utility';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UserSelect from '../../components/UI/Form/UserSelect';
import axios from '../../axios-admin';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';

class TranslatorForm extends React.Component {
    
    state = {
        controls: {
            user_id: {
                value : '',
                validation: {
                    required: true,
                    isInt: true
                },
                valid: false,
                touched: false,
            },
            position: {
                value : '',
                valid: false,
                touched: false,
            }
        },
        formIsValid: false,
        translators: []
    }

    componentDidMount() {
        this.fetchTranslators();
    }

    fetchTranslators(){
        axios.get(this.props.type + '/' + this.props.data.id + '/translators')
        .then(res => {
            this.setState({translators:res.data.data});
        })
        .catch(err => {
            console.log(err.response.data);
        });
    }
    
    inputChangedHandler = event => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    selectChangedHandler = (option, inputIdentifier) => {

        const updatedControl = {
            ...this.state.controls
        };

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        updatedControlElement.value = option.value;
        updatedControlElement.valid = checkValidity(option.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        updatedControl[inputIdentifier] = updatedControlElement;

        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControl, formIsValid: formIsValid});

    }
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }

        axios.post(this.props.type + '/' + this.props.data.id + '/translators', formData)
        .then(res => {
            this.fetchTranslators();
        })
        .catch(err => {
            console.log(err.response.data);
        });
    }

    removeTranslator(id){
        axios.delete(this.props.type + '/' + this.props.data.id + '/translators/' + id)
        .then(res => {
            const updatedTranslators = this.state.translators.filter(translator => translator.id !== id);
            this.setState({translators:updatedTranslators});
        })
        .catch(err => {
            console.log(err.response.data);
        });
    }
    
    
  render() {


    return (
        <form noValidate onSubmit={e => this.submitHandler(e) }>
            <Grid container spacing={16} direction="row" justify="center">
                
                <Grid item sm={12}>
                    <UserSelect 
                        team
                        selected={this.state.controls.user_id.value} 
                        change={(option) => this.selectChangedHandler(option, 'user_id')} />
                </Grid>
                <Grid item sm={12}>
                    <InputLabel>Position</InputLabel>
                    <Select value={this.state.controls.position.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'position'}}>
                        <MenuItem value="">-- --</MenuItem>
                        <MenuItem value="Vertėjas">Vertėjas</MenuItem>
                        <MenuItem value="Redaktorius">Redaktorius</MenuItem>
                        <MenuItem value="Įgarsintojas">Įgarsintojas</MenuItem>
                    </Select>
                </Grid>
                
                <Grid item sm={12}>
                    <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                </Grid>

                <Grid item sm={12}>
                    <Table style={{fontSize:"0.8em"}}>
                    <TableBody>
                        {this.state.translators.map(tranlator => {
                            return (
                                <TableRow key={tranlator.id}>
                                    <TableCell padding="none">{tranlator.user.username}</TableCell>
                                    <TableCell padding="none">{tranlator.position}</TableCell>
                                    <TableCell padding="none">
                                        <div onClick={event => this.removeTranslator(tranlator.id)}>X</div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </form>
    );
  }
}

export default TranslatorForm;