import React from 'react';
import GenreSelect from '../../components/UI/Form/GenreSelect';
import StudioSelect from '../../components/UI/Form/StudioSelect';
import ImageUploadResizer from 'react-form-upload-image-resize';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import InputLabel from '@material-ui/core/InputLabel';
import  { formInputChangedHandler, validateControlsForm } from '../../shared/utility';

class AnimeForm extends React.Component {
    
    state = {
        anime: [],
        controls: {
            image: {
                value : (this.props.data) ? this.props.data.image_url : '',
                validation: {
                    required: true,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            wallpaper: {
                value : this.props.data ? this.props.data.wallpaper : '',
                validation: {
                    required: false,
                },
                valid: true,
                touched: true
            },
            name: {
                value : (this.props.data) ? this.props.data.name : '',
                validation: {
                    required: true,
                    minLength: 2
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false
            },
            alternativeName: {
                value : (this.props.data && this.props.data.alternativeName) ? this.props.data.alternativeName : '',
                validation: {
                    required: false
                },
                valid: true,
                touched: true
            },
            name_lt: {
                value : this.props.data ? this.props.data.name_lt : '',
                validation: {
                    required: false
                },
                valid: true,
                touched: true
            },
            type: {
                value : (this.props.data) ? this.props.data.type : 0,
                valid: true,
                touched: true,
            },
            series: {
                value : (this.props.data) ? this.props.data.series : 1,
                validation: {
                    required: true,
                    minLength: 1,
                    isInt: true
                },
                valid: true,
                touched: true
            },
            year: {
                value : (this.props.data) ? this.props.data.year : '',
                validation: {
                    required: true,
                    min: 1950,
                    isInt: true
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            mal: {
                value : (this.props.data) ? this.props.data.mal : '',
                validation: {
                    required: false,
                    isInt: true
                },
                valid: true,
                touched: true,
            },
            anidb: {
                value : (this.props.data) ? this.props.data.anidb : '',
                validation: {
                    required: false,
                    isInt: true
                },
                valid: true,
                touched: true,
            },
            status: {
                value : (this.props.data) ? this.props.data.status : false,
                valid: true,
                touched: true,
            },
            n18: {
                value : (this.props.data) ? this.props.data.n18 : false,
                valid: true,
                touched: true,
            },
            new_season: {
                value : (this.props.data) ? this.props.data.new_season : false,
                valid: true,
                touched: true,
            },
            bbcode: {
                value : (this.props.data) ? this.props.data.bbcode : false,
                valid: true,
                touched: true,
            },
            genres: {
                value : (this.props.data) ? this.props.data.genres.map(genre => genre.genre) : [],
                validation: {},
                valid: true,
                touched: true,
            },
            studios: {
                value : (this.props.data) ? this.props.data.studios.map(studio => studio.studio_id) : [],
                validation: {},
                valid: true,
                touched: true,
            },
            description: {
                value : (this.props.data) ? this.props.data.description : '',
                validation: {
                    required: true,
                    minLength: 10,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            display_time: {
                value : (this.props.data) ? this.props.data.display_time : "2019-09-06T10:30",
                valid: true,
                touched: true,
            },
            youtube: {
                value : (this.props.data) ? this.props.data.youtube : '',
                validation: {
                    minLength: 15,
                },
                valid: true,
                touched: true,
            },
        },
        formIsValid: (this.props.data) ? true : false,
    }

    imageUploadHandler = (inputIdentifier, base64) => {
        const updatedControl = { ...this.state.controls }

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        }

        updatedControlElement.value = base64;
        updatedControlElement.valid = true;
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }
        this.setState(formInputChangedHandler(this.state.controls, inputIdentifier, event.target.value));
    }
    
    switchHandler = event => {
        this.setState(formInputChangedHandler(this.state.controls, event.target.id, !this.state.controls[event.target.id].value));
    }
    
    selectChangedHandler = (option, inputIdentifier) => {
        this.setState(formInputChangedHandler(this.state.controls,inputIdentifier, option.value));
    }
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            const updatedControls = validateControlsForm(this.state.controls);
            this.setState({ controls: updatedControls });
            return false;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }
  
        this.props.success(formData);
    }
    
    
  render() {
      
    let image = null;
    if(this.state.controls.image.value !== ''){
        image = <img src={this.state.controls.image.value} alt="anime" style={{width: "100%"}} />
    }

    return (
        <form noValidate onSubmit={e => this.submitHandler(e) }>
            <Grid container spacing={16} direction="row" justify="center">
                <Grid item md={2} sm={4}>
                    {image}
                </Grid>
                
                <Grid item md={10} sm={8}>
                    <Grid container spacing={8}>
                    
                        <Grid item md={6}>
                            <InputLabel>Image </InputLabel>
                            <ImageUploadResizer name="image" change={base64 => this.imageUploadHandler('image', base64)} width={300} height={450} />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Wallpaper </InputLabel>
                            <ImageUploadResizer name="wallpaper" change={base64 => this.imageUploadHandler('wallpaper', base64)} width={1920} height={1080} />
                        </Grid>
                        <Grid item md={4}>
                            <InputLabel>Title</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.name.value}
                                id="name" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.name.valid && this.state.controls.name.touched} />
                        </Grid>
                        <Grid item md={4}>
                            <InputLabel>Alernative Name</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.alternativeName.value}
                                id="alternativeName" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.alternativeName.valid && this.state.controls.alternativeName.touched} />
                        </Grid>
                        <Grid item md={4}>
                            <InputLabel>Name in Lt</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.name_lt.value}
                                id="name_lt" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.name_lt.valid && this.state.controls.name_lt.touched} />
                        </Grid>
                        <Grid item md={2} sm={6}>
                            <InputLabel>Type</InputLabel>
                            <Select value={this.state.controls.type.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'type'}}>
                                <MenuItem value="TV">TV</MenuItem>
                                <MenuItem value="OVA">OVA</MenuItem>
                                <MenuItem value="Filmas">MOVIE</MenuItem>
                                <MenuItem value="Special">Special</MenuItem>
                                <MenuItem value="ONA">ONA</MenuItem>
                                <MenuItem value="Web">Web</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={2} sm={4}>
                            <InputLabel>Epizodes</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.series.value}
                                id="series" 
                                onChange={this.inputChangedHandler} 
                                type="number"
                                error={!this.state.controls.series.valid && this.state.controls.series.touched} />
                        </Grid>
                        <Grid item md={2} sm={4}>
                            <InputLabel>Year</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.year.value}
                                id="year" 
                                onChange={this.inputChangedHandler} 
                                type="number"
                                error={!this.state.controls.year.valid && this.state.controls.year.touched} />
                        </Grid>
                        <Grid item md={2} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="new_season" id="new_season" checked={this.state.controls.new_season.value} onChange={this.switchHandler}  />
                              }
                              label="New Season"
                            />
                        </Grid>
                        <Grid item md={2} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="n18" id="n18" checked={this.state.controls.n18.value} onChange={this.switchHandler}  />
                              }
                              label="n18"
                            />
                        </Grid>
                        <Grid item md={2} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="status" id="status" checked={this.state.controls.status.value} onChange={this.switchHandler}  />
                              }
                              label="Active"
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <InputLabel>Youtube</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.youtube.value}
                                id="youtube" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.youtube.valid && this.state.controls.youtube.touched} />
                        </Grid>
                        <Grid item sm={2}>
                            <InputLabel>MAL</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.mal.value}
                                id="mal" 
                                onChange={this.inputChangedHandler} 
                                type="number"
                                error={!this.state.controls.mal.valid && this.state.controls.mal.touched} />
                        </Grid>
                        <Grid item sm={2}>
                            <InputLabel>AniDB</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.anidb.value}
                                id="anidb" 
                                onChange={this.inputChangedHandler} 
                                type="number"
                                error={!this.state.controls.anidb.valid && this.state.controls.anidb.touched} />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                id="display_time"
                                label="Added On"
                                onChange={this.inputChangedHandler}
                                value={this.state.controls.display_time.value}
                                type="datetime-local"
                                InputLabelProps={{shrink: true}}
                              />
                        </Grid>
                        
                        <Grid item md={6}>
                            <InputLabel>Studios</InputLabel>
                            <StudioSelect id="studios" value={this.state.controls.studios.value} change={(option) => this.selectChangedHandler(option, 'studios')} />
                        </Grid>
                        
                        <Grid item md={6}>
                            <InputLabel>Genres</InputLabel>
                            <GenreSelect id="genres" selected={this.state.controls.genres.value} change={(option) => this.selectChangedHandler(option, 'genres')} />
                        </Grid>
                        
                    </Grid>
                    
                    
                </Grid>
                
                <Grid item sm={12}>
                    <TextField 
                        fullWidth 
                        multiline 
                        label="Description"
                        name="description" 
                        id="description" 
                        required 
                        rows="10" 
                        error={!this.state.controls.description.valid && this.state.controls.description.touched}
                        onChange={this.inputChangedHandler}
                        value={this.state.controls.description.value}  />
                </Grid>
                
                <Grid item sm={3}>
                <FormControlLabel
                      control={
                        <Switch value="bbcode" id="bbcode" checked={this.state.controls.bbcode.value} onChange={this.switchHandler}  />
                      }
                      label="BBCode"
                    />
                </Grid>
                
                <Grid item sm={12}>
                    <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                </Grid>
            </Grid>
        </form>
    );
  }
}

export default AnimeForm;