import React from 'react';
import { checkValidity } from '../../shared/utility.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import axios from '../../axios-admin.js';
import AnimeSelect from '../../components/UI/Form/AnimeSelect';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class ProjectsNew extends React.Component {
    
    state = {
        controls: {
            anime_id: {
                value : '',
                validation: {
                    required: true,
                    isInt: true,
                },
                valid: false,
                touched: false,
            },
            translators: {
                value : '',
                validation: {
                    required: true,
                    minLength: 2,
                },
                valid: false,
                touched: false,
            },
            editors: {
                value : '',
                validation: {
                    required: false,
                    minLength: 2,
                },
                valid: true,
                touched: false,
            },
            type: {
                value : 'subs',
                valid: true,
                touched: true,
            },
        },
        formIsValid: false,
        saved: false
    }

    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }

    selectChangedHandler = (option) => {
        const inputIdentifier = 'anime_id';
        const updatedControl = {
            ...this.state.controls
        };

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        updatedControlElement.value = option.value;
        updatedControlElement.valid = checkValidity(option.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        updatedControl[inputIdentifier] = updatedControlElement;

        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControl, formIsValid: formIsValid});

    }

    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }

        axios.post('projects', formData)
        .then(res => {
            this.setState({saved: true})
        })
    }


  render() {
    let notice = null;
    if(this.state.saved){
        notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Created OK</div>;
    }

    return (
        <Paper>
            {notice}
            <h2>New Project</h2>
            <form noValidate onSubmit={e => this.submitHandler(e) }>
            <Grid container spacing={16} direction="row" justify="center">

                <Grid item md={8}>
                    <AnimeSelect 
                        id="anime_id" 
                        selected={this.state.controls.anime_id.value} 
                        change={(option) => this.selectChangedHandler(option)} />
                </Grid>
                <Grid item md={3}>
                    <InputLabel>Type</InputLabel>
                    <Select value={this.state.controls.type.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'type'}}>
                        <MenuItem value="subs">subs</MenuItem>
                        <MenuItem value="audio">audio</MenuItem>
                    </Select>
                </Grid>
                <Grid item md={5}>
                    <InputLabel>Translators</InputLabel>
                    <Input 
                        fullWidth 
                        value={this.state.controls.translators.value}
                        id="translators" 
                        onChange={this.inputChangedHandler} 
                        error={!this.state.controls.translators.valid && this.state.controls.translators.touched} />
                </Grid>
                <Grid item md={5}>
                    <InputLabel>Editors</InputLabel>
                    <Input 
                        fullWidth 
                        value={this.state.controls.editors.value}
                        id="editors" 
                        onChange={this.inputChangedHandler} 
                        error={!this.state.controls.editors.valid && this.state.controls.editors.touched} />
                </Grid>
                
                <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
            </Grid>
        </form>
       </Paper>
    );
  }
}

export default ProjectsNew;