import React from 'react';
import { checkValidity } from '../../shared/utility.js';
import ImageUploadResizer from 'react-form-upload-image-resize';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import InputLabel from '@material-ui/core/InputLabel';

class MangaForm extends React.Component {
    
    state = {
        anime: [],
        controls: {
            image: {
                value : (this.props.data) ? this.props.data.image_url : '',
                validation: {
                    required: true,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            title: {
                value : (this.props.data) ? this.props.data.title : '',
                validation: {
                    required: true,
                    minLength: 2,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            author: {
                value : (this.props.data) ? this.props.data.author : '',
                validation: {
                    required: false
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            type: {
                value : (this.props.data) ? this.props.data.type : 0,
                valid: true,
                touched: true,
            },
            volumes: {
                value : (this.props.data) ? this.props.data.volumes : 0,
                validation: {
                    required: true,
                    minLength: 0,
                    isInt: true
                },
                valid: true,
                touched: true,
            },
            chapters: {
                value : (this.props.data) ? this.props.data.chapters : 1,
                validation: {
                    required: true,
                    minLength: 1,
                    isInt: true
                },
                valid: true,
                touched: true,
            },
            year_start: {
                value : (this.props.data) ? this.props.data.year_start : '',
                validation: {
                    required: true
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            year_end: {
                value : (this.props.data) ? this.props.data.year_end : '',
                validation: {
                },
                valid: true,
                touched: true,
            },
            status: {
                value : (this.props.data) ? this.props.data.status : false,
                valid: true,
                touched: true,
            },
            bbcode: {
                value : (this.props.data) ? this.props.data.bbcode : false,
                valid: true,
                touched: true,
            },
            description: {
                value : (this.props.data) ? this.props.data.description : '',
                validation: {
                    required: true
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            }
        },
        formIsValid: (this.props.data) ? true : false,
    }

    imageUploadHandler = (base64) => {

        const inputIdentifier = 'image';

        const updatedControl = {
            ...this.state.controls
        }

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        }

        updatedControlElement.value = base64;
        updatedControlElement.valid = true;
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    switchHandler = event => {
        const inputIdentifier = event.target.id;
        
        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = !updatedControlElement.value;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    selectChangedHandler = (option, inputIdentifier) => {

        const updatedControl = {
            ...this.state.controls
        };

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        updatedControlElement.value = option.value;
        updatedControlElement.valid = checkValidity(option.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        updatedControl[inputIdentifier] = updatedControlElement;

        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControl, formIsValid: formIsValid});

    }
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }
  
        this.props.success(formData);
    }
    
    
  render() {
      
    let image = null;
    if(this.state.controls.image.value !== ''){
        image = <img src={this.state.controls.image.value} alt="anime" style={{width: "100%"}} />
    }
      

    return (
        <form noValidate onSubmit={e => this.submitHandler(e) }>
            <Grid container spacing={16} direction="row" justify="center">
                <Grid item md={3} sm={5}>
                    {image}
                </Grid>
                
                <Grid item md={9} sm={7}>
                    <Grid container spacing={8}>
                    
                        <Grid item md={12}>
                            <ImageUploadResizer id="image" name="image" change={this.imageUploadHandler}  width={260} height={350} />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Title</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.title.value}
                                id="title" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.title.valid && this.state.controls.title.touched} />
                        </Grid>
                        <Grid item md={2} sm={6}>
                            <InputLabel>Type</InputLabel>
                            <Select value={this.state.controls.type.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'type'}}>
                                <MenuItem value="Manga">Manga</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={2} sm={3}>
                            <InputLabel>Volumes</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.volumes.value}
                                id="volumes" 
                                onChange={this.inputChangedHandler} 
                                type="number"
                                error={!this.state.controls.volumes.valid && this.state.controls.volumes.touched} />
                        </Grid>
                        <Grid item md={2} sm={3}>
                            <InputLabel>Chapters</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.chapters.value}
                                id="chapters" 
                                onChange={this.inputChangedHandler} 
                                type="number"
                                error={!this.state.controls.chapters.valid && this.state.controls.chapters.touched} />
                        </Grid>
                        <Grid item md={3} sm={4}>
                            <InputLabel>Start Year</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.year_start.value}
                                id="year_start" 
                                onChange={this.inputChangedHandler} 
                                type="date"
                                error={!this.state.controls.year_start.valid && this.state.controls.year_start.touched} />
                        </Grid>
                        <Grid item md={3} sm={4}>
                            <InputLabel>End Year</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.year_end.value}
                                id="year_end" 
                                onChange={this.inputChangedHandler} 
                                type="date"
                                error={!this.state.controls.year_end.valid && this.state.controls.year_end.touched} />
                        </Grid>
                        <Grid item md={3} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="status" id="status" checked={this.state.controls.status.value} onChange={this.switchHandler}  />
                              }
                              label="Active"
                            />
                        </Grid>

                        <Grid item sm={3}>
                        <FormControlLabel
                            control={
                                <Switch value="bbcode" id="bbcode" checked={this.state.controls.bbcode.value} onChange={this.switchHandler}  />
                            }
                            label="BBCode"
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <InputLabel>author</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.author.value}
                                id="author" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.author.valid && this.state.controls.author.touched} />
                        </Grid>
                        
                    </Grid>
                    
                    
                </Grid>
                
                <Grid item sm={12}>
                    <TextField 
                        fullWidth 
                        multiline 
                        label="Description"
                        name="description" 
                        id="description" 
                        required 
                        rows="10" 
                        onChange={this.inputChangedHandler}
                        value={this.state.controls.description.value}  />
                </Grid>
                
                <Grid item sm={12}>
                    <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                </Grid>
            </Grid>
        </form>
    );
  }
}

export default MangaForm;