import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://api.amf.local/api/admin/' : 'https://api.amf.lt/api/admin/',
    //baseURL: 'http://api.amf.lt/api/',
    headers: {
        Authorization: {
          toString () {
            return `Bearer ${localStorage.getItem('token')}`
          }
        }
    }
});

export default instance;