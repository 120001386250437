import React from 'react';
import { checkValidity } from '../../shared/utility.js';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import PollOptionForm from './PollOptionForm';
import axios from '../../axios-admin';

class PollForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.onAddOptionHandler = this.onAddOptionHandler.bind(this);
        this.onOptionDelete = this.onOptionDelete.bind(this);
    }

    state = {
        controls: {
            name: {
                value : (this.props.data) ? this.props.data.name : '',
                validation: {
                    required: true,
                    minLength: 5,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            description: {
                value : (this.props.data) ? this.props.data.description : '',
                validation: {
                    required: true,
                    minLength: 5,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            status: {
                value : (this.props.data) ? this.props.data.status : false,
                valid: true,
                touched: true,
            },
            startdate: {
                value : (this.props.data) ? this.props.data.startdate : "2019-04-24T10:30",
                valid: true,
                touched: true,
            },
            enddate: {
                value : (this.props.data) ? this.props.data.enddate : "2019-04-24T10:30",
                valid: true,
                touched: true,
            },
            display_to_date: {
                value : (this.props.data) ? this.props.data.display_to_date : "2019-04-24T10:30",
                valid: true,
                touched: true,
            },
            
        },
        options: this.props.data ? this.props.data.options.map(option => { return {id: option.id, question: option.poll_option, votes: option.votes_count}}) : [],
        formIsValid: (this.props.data) ? true : false,
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    switchHandler = event => {
        const inputIdentifier = event.target.id;
        
        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = !updatedControlElement.value;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }
        
        const data = {
            name            : formData.name,
            description     : formData.description,
            status          : formData.status,
            display_to_date : formData.display_to_date,
            startdate       : formData.startdate,
            enddate         : formData.enddate,
            options         : [...this.state.options]
        };
        
        this.props.success(data);
    }

    onAddOptionHandler(data){
        const updatedOptions = [...this.state.options];
        updatedOptions.push({question: data, votes: 0});
        this.setState({options: updatedOptions});
    }

    onOptionDelete(e, option){
        e.preventDefault();
        console.log('delete', option);
        if(!option.id){
            console.log('blank delete');
            return ;
        }

        const url = 'polls/' + this.props.data.id + '/option/' + option.id;
        
        axios.delete(url)
        .then(res => {
            const updatedOptions = this.state.options.filter(o => {
                return o.id !== option.id
            })
            this.setState({options: updatedOptions});
        })
        .catch(err => {
            console.log('create fail', err);
        });
    }
    
  render() {
    console.log('props', this.props)
    return (
        <Grid container spacing={8}>
            <Grid item sm={12}>
                <form noValidate onSubmit={e => this.submitHandler(e) }>
                    <Grid container direction="column" spacing={0}>

                        <Grid item>
                            <InputLabel>Name</InputLabel>
                            <Input 
                                fullWidth 
                                id="name"
                                placeholder="Poll name?"
                                value={this.state.controls.name.value}
                                error={!this.state.controls.name.valid && this.state.controls.name.touched}
                                onChange={this.inputChangedHandler} />
                        </Grid>

                        <Grid item>
                            <InputLabel>Description</InputLabel>
                            <Input 
                                fullWidth 
                                id="description"
                                placeholder="Poll description"
                                value={this.state.controls.description.value}
                                error={!this.state.controls.description.valid && this.state.controls.description.touched}
                                onChange={this.inputChangedHandler} />
                        </Grid>
                        
                        <Grid item>
                            <Grid container spacing={8}>
                                <Grid item sm={4}>
                                    <TextField
                                        label="Start Time"
                                        id="startdate"
                                        onChange={this.inputChangedHandler}
                                        value={this.state.controls.startdate.value}
                                        type="datetime-local"
                                        InputLabelProps={{shrink: true}}
                                      />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        label="End time"
                                        id="enddate"
                                        onChange={this.inputChangedHandler}
                                        value={this.state.controls.enddate.value}
                                        type="datetime-local"
                                        InputLabelProps={{shrink: true}}
                                      />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        label="Display Until"
                                        id="display_to_date"
                                        onChange={this.inputChangedHandler}
                                        value={this.state.controls.display_to_date.value}
                                        type="datetime-local"
                                        InputLabelProps={{shrink: true}}
                                      />
                                </Grid>
                                <Grid item sm={3}>
                                    <FormControlLabel
                                        control={
                                            <Switch value="status" id="status" checked={this.state.controls.status.value} onChange={this.switchHandler}  />
                                        }
                                        label="Status"
                                        />
                                </Grid>
                                <Grid item sm={12}>
                                    Options:
                                    <Table>
                                        <TableBody>
                                            {this.state.options.map(obj => {
                                                let deleteBtn = null;
                                                if(obj.votes < 1){
                                                    deleteBtn = <Button onClick={e => this.onOptionDelete(e, obj)}>delete</Button>;
                                                }
                                                return (
                                                    <TableRow key={obj.question}>
                                                        <TableCell>{obj.question}</TableCell>
                                                        <TableCell>{obj.votes}</TableCell>
                                                        <TableCell>{deleteBtn}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                    <PollOptionForm add={this.onAddOptionHandler} />
                                </Grid>
                                <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            
       </Grid>
    );
  }
}

export default PollForm;