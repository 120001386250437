import React from 'react';
import { checkValidity } from '../../shared/utility.js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

class PollOptionForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.submitHandler = this.submitHandler.bind(this);
    }

    state = {
        value : '',
        validation: {
            required: true,
            minLength: 2,
        },
        valid: false,
        touched: false,
    }
    
    inputChangedHandler = (event) => {
        this.setState({value: event.target.value, valid: checkValidity(event.target.value, this.state.validation), touched: true});
    }
    
    submitHandler(){
        
        if(!this.state.valid){
            return ;
        }
        this.props.add(this.state.value);
    }
    
    render() {
        return (
            <Grid container spacing={8}>
                <Grid item sm={12}>
                        <Grid container direction="row" spacing={0}>
                            <Grid item sm={3}>
                                <InputLabel>Poll Option</InputLabel>
                            </Grid>
                            <Grid item sm={6}>
                                <Input 
                                    fullWidth 
                                    id="option"
                                    placeholder="New Poll Option"
                                    value={this.state.value}
                                    error={!this.state.valid && this.state.touched}
                                    onChange={this.inputChangedHandler} />
                            </Grid>
                            
                            <Grid item sm={3}>
                                <Button onClick={this.submitHandler} variant="contained" fullWidth  type="submit" color="primary">Add Option</Button>
                            </Grid>
                        </Grid>
                </Grid>
                
        </Grid>
        );
    }
}

export default PollOptionForm;