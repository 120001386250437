import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import OnlinePlanTable from './OnlinePlanTable';
import axios from '../../axios-admin';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

class OnlineList extends React.PureComponent {
    
    state = {
        data: null,
    }
    
    componentDidMount() {
        this.fetchData();
    }
    
    fetchData(){
        axios.get('online/plans')
        .then(res => {
            this.setState({data:res.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }

  render() {
    const { classes } = this.props;
    
    return (
        <Paper className={classes.root}>
          <h2>Online Planned</h2>
          <OnlinePlanTable data={this.state.data} />
       </Paper>
    );
  }
}

OnlineList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OnlineList);