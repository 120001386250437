import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import UsersForm from './UsersForm';
import axios from '../../axios-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class UsersView extends Component {

    constructor(props) {
        super(props);
        this.onSuccess = this.onSuccess.bind(this);
    }
    
    state = {
        success: false,
        data: null
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;
        
        axios.get('users/' + id)
        .then(res => {
            this.setState({data:res.data.data});
        })
        .catch(err => {
            console.log(err.response);
        });
    }
    
    onSuccess(data){
        
        const url = 'users/' + this.props.match.params.id;
        
        axios.post(url, data)
        .then(res => {
            if(res.data.message === 'success'){
                this.setState({success: true});
            }
        })
        .catch(err => {
            console.log('update fail', err);
        });
    }
    
    render() {
        let notice = null;
        let rows = [];
        if(this.state.success){
            notice = <div style={{padding: "10px", backgroundColor: "#41B883", color: "#fff", margin: "10px"}}>Update OK</div>;
        }
        let form = null;
        if(this.state.data){
            form = <UsersForm data={this.state.data} success={this.onSuccess} />;

            rows = this.state.data.video_logs.map(obj => {

                return (
                  <TableRow key={obj.id}>
                      <TableCell padding="dense">{obj.online.anime.name}</TableCell>
                      <TableCell padding="dense">{obj.online.ep}</TableCell>
                      <TableCell padding="dense">{obj.updated_at}</TableCell>
                      <TableCell padding="dense">{obj.ip}</TableCell>
                      <TableCell padding="dense">{obj.progress}</TableCell>
                  </TableRow>
                );
            });
        }

        return (
            <div>
            <Paper>{notice}{form}</Paper>
                <Paper>
            <h2>Srautas</h2>
            <Table>
            <TableHead>
                <TableRow>
                <TableCell padding="dense">Anime</TableCell>
                <TableCell padding="dense">Ep</TableCell>
                <TableCell padding="dense">Logged</TableCell>
                <TableCell padding="dense">Ip</TableCell>
                <TableCell padding="dense">Progress</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
            </Table>
        </Paper>
        </div>
        )
    }   
}

export default UsersView;