import React from 'react';
import { checkValidity } from '../../shared/utility.js';
import ImageUploadResizer from 'react-form-upload-image-resize';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

import InputLabel from '@material-ui/core/InputLabel';

class UsersForm extends React.Component {
    
    state = {
        anime: [],
        controls: {
            profile_image: {
                value : this.props.data.profile_image_url,
                validation: {
                    required: true,
                },
                valid: true,
                touched: false,
            },
            background_image: {
                value : this.props.data.profile_background_url,
                validation: {
                    required: true,
                },
                valid: true,
                touched: false,
            },
            username: {
                value : this.props.data.username,
                validation: {
                    required: true,
                    minLength: 2,
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            title: {
                value : this.props.data.title,
                validation: {
                    required: false,
                    minLength: 2,
                },
                valid: true,
                touched: true,
            },
            email: {
                value : this.props.data.email,
                validation: {
                    required: false
                },
                valid: true,
                touched: true,
            },
            roles: {
                value : this.props.data.roles,
                valid: true,
                touched: true,
            },
            member: {
                value : this.props.data.member,
                valid: true,
                touched: true,
            },
            gender: {
                value : this.props.data.gender,
                valid: true,
                touched: true,
            },
            restricted: {
                value : this.props.data.restricted,
                valid: true,
                touched: true,
            },
            is_translator: {
                value : this.props.data.is_translator,
                valid: true,
                touched: true,
            },
            displayN18: {
                value : this.props.data.displayN18,
                valid: true,
                touched: true,
            },
            enabled: {
                value : this.props.data.enabled,
                valid: true,
                touched: true,
            },
            description: {
                value : (this.props.data) ? this.props.data.description : '',
                validation: {
                    required: true
                },
                valid: (this.props.data) ? true : false,
                touched: (this.props.data) ? true : false,
            },
            ban_until: {
                value : this.props.data.ban_until,
                valid: true,
                touched: true,
            },
        },
        formIsValid: (this.props.data) ? true : false,
    }

    imageUploadHandler = (base64) => {

        const inputIdentifier = 'image';

        const updatedControl = {
            ...this.state.controls
        }

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        }

        updatedControlElement.value = base64;
        updatedControlElement.valid = true;
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    inputChangedHandler = (event) => {
        let inputIdentifier = null;
        if(event.target.id){
            inputIdentifier = event.target.id;
        } else if (event.target.name){
            inputIdentifier = event.target.name;
        } else {
            return ;
        }

        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = event.target.value;
        updatedControlElement.valid = checkValidity(event.target.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    switchHandler = event => {
        const inputIdentifier = event.target.id;
        
        const updatedControl = {
            ...this.state.controls
        };
        
        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        
        updatedControlElement.value = !updatedControlElement.value;
        
        updatedControl[inputIdentifier] = updatedControlElement;
        
        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }
        
        this.setState({controls: updatedControl, formIsValid: formIsValid});
    }
    
    selectChangedHandler = (option, inputIdentifier) => {

        const updatedControl = {
            ...this.state.controls
        };

        const updatedControlElement = {
            ...updatedControl[inputIdentifier]
        };
        updatedControlElement.value = option.value;
        updatedControlElement.valid = checkValidity(option.value, updatedControlElement.validation);
        updatedControlElement.touched = true;
        updatedControl[inputIdentifier] = updatedControlElement;

        let formIsValid = true;
        for(let inputIdentifier in updatedControl){
            formIsValid = updatedControl[inputIdentifier].valid && formIsValid;
        }

        this.setState({controls: updatedControl, formIsValid: formIsValid});

    }
    
    submitHandler(event){
        event.preventDefault();
        
        if(!this.state.formIsValid){
            console.log('invalid form', this.state.controls);
            return ;
        }
        
        const formData = {};
        for (let formElementIdentifier in this.state.controls) {
            formData[formElementIdentifier] = this.state.controls[formElementIdentifier].value;
        }
        
        this.props.success(formData);
    }
    
    
  render() {

    return (
        <form noValidate onSubmit={e => this.submitHandler(e) }>
            <Grid container spacing={8} direction="row" justify="center" style={{padding: "10px"}}>
                <Grid item md={4} sm={7}>
                    <img src={this.state.controls.background_image.value} alt="background" style={{width: "100%"}} />
                </Grid>
                <Grid item md={2} sm={5}>
                    <img src={this.state.controls.profile_image.value} alt="profile" style={{width: "100%"}} />
                </Grid>
                <Grid item md={6} sm={5}>
                    <Grid container spacing={8}>
                        <Grid item md={6}>
                            <InputLabel>Profile Image</InputLabel>
                            <ImageUploadResizer id="image" 
                                                name="image" 
                                                change={this.imageUploadHandler}  
                                                width={260} height={350} />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Background image</InputLabel>
                            <ImageUploadResizer id="image" 
                                                name="image" 
                                                change={this.imageUploadHandler}  
                                                width={260} height={350} />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Username</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.username.value}
                                id="username" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.username.valid && this.state.controls.username.touched} />
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>Title</InputLabel>
                            <Input 
                                fullWidth 
                                value={this.state.controls.title.value}
                                id="title" 
                                onChange={this.inputChangedHandler} 
                                error={!this.state.controls.title.valid && this.state.controls.title.touched} />
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>Role</InputLabel>
                            <Select value={this.state.controls.roles.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'roles'}}>
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="modetator">Modetator</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>Email</InputLabel>
                            <Input 
                                fullWidth 
                                disabled
                                value={this.state.controls.email.value}
                                id="email" />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12} sm={7}>
                    <Grid container spacing={8}>
                        <Grid item md={2}>
                            <InputLabel>Gender</InputLabel>
                            <Select value={this.state.controls.gender.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'gender'}}>
                                <MenuItem value="M">Male</MenuItem>
                                <MenuItem value="F">Female</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={2}>
                            <InputLabel>Member</InputLabel>
                            <Select value={this.state.controls.member.value} onChange={this.inputChangedHandler} fullWidth inputProps={{ name: 'member'}}>
                                <MenuItem value="Narys">Narys (default)</MenuItem>
                                <MenuItem value="Komanda">Komanda</MenuItem>
                            </Select>
                        </Grid>
                        
                        <Grid item md={2} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="is_translator" id="is_translator" checked={this.state.controls.is_translator.value} onChange={this.switchHandler}  />
                              }
                              label="Translator"
                            />
                        </Grid>
                        <Grid item md={2} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="restricted" id="restricted" checked={this.state.controls.restricted.value} onChange={this.switchHandler}  />
                              }
                              label="Restricted"
                            />
                        </Grid>
                        <Grid item md={2} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="enabled" id="enabled" checked={this.state.controls.enabled.value} onChange={this.switchHandler}  />
                              }
                              label="Enabled"
                            />
                        </Grid>
                        <Grid item md={2} sm={4}>
                        <FormControlLabel
                              control={
                                <Switch value="displayN18" id="displayN18" checked={this.state.controls.displayN18.value} onChange={this.switchHandler}  />
                              }
                              label="Display N18"
                            />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                id="ban_until"
                                label="Ban Until"
                                onChange={this.inputChangedHandler}
                                value={this.state.controls.ban_until.value}
                                type="date"
                                InputLabelProps={{shrink: true}}
                              />
                        </Grid>
                        
                        
                        
                    </Grid>
                    
                    
                </Grid>
                
                <Grid item sm={12}>
                    <TextField 
                        fullWidth 
                        multiline 
                        label="Description"
                        name="description" 
                        id="description" 
                        required 
                        rows="10" 
                        onChange={this.inputChangedHandler}
                        value={this.state.controls.description.value}  />
                </Grid>
                
                <Grid item sm={12}>
                    <Button variant="contained" fullWidth  type="submit" color="primary">Submit</Button>
                </Grid>
            </Grid>
        </form>
    );
  }
}

export default UsersForm;