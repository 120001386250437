import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import FaceIcon from '@material-ui/icons/Face';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import MoneyIcon from '@material-ui/icons/Money';
import TimelineIcon from '@material-ui/icons/Timeline';
import BookIcon from '@material-ui/icons/Book';
import LibraryBookIcon from '@material-ui/icons/LibraryBooks';
import MapIcon from '@material-ui/icons/Map';
import PagesIcon from '@material-ui/icons/Pages';
import ContactsIcon from '@material-ui/icons/Contacts';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import WebIcon from '@material-ui/icons/Web';

export const mainListItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to="/users">
      <ListItemIcon>
        <ContactsIcon />
      </ListItemIcon>
     <ListItemText primary="Users" />
    </ListItem>
    <ListItem button component={Link} to="/fingerprints">
      <ListItemIcon>
        <FingerprintIcon />
      </ListItemIcon>
     <ListItemText primary="Fingerprints" />
    </ListItem>
    <ListItem button component={Link} to="/articles">
      <ListItemIcon>
        <PagesIcon />
      </ListItemIcon>
     <ListItemText primary="Articles" />
    </ListItem>
    <ListItem button component={Link} to="/anime">
      <ListItemIcon>
        <BookIcon />
      </ListItemIcon>
     <ListItemText primary="Anime" />
    </ListItem>
    <ListItem button component={Link} to="/anime-recommendations">
      <ListItemIcon>
        <BookIcon />
      </ListItemIcon>
     <ListItemText primary="Anime Recomm" />
    </ListItem>
    <ListItem button component={Link} to="/studios">
      <ListItemIcon>
        <WebIcon />
      </ListItemIcon>
     <ListItemText primary="Studios" />
    </ListItem>
    <ListItem button component={Link} to="/online">
      <ListItemIcon>
        <VideoLibraryIcon />
      </ListItemIcon>
      <ListItemText primary="Online" />
    </ListItem>
    <ListItem button component={Link} to="/plans">
      <ListItemIcon>
        <MapIcon />
      </ListItemIcon>
      <ListItemText primary="Plans" />
    </ListItem>
    <ListItem button component={Link} to="/manga">
      <ListItemIcon>
        <LibraryBookIcon />
      </ListItemIcon>
      <ListItemText primary="Manga" />
    </ListItem>
    <ListItem button component={Link} to="/join-team">
      <ListItemIcon>
        <FaceIcon />
      </ListItemIcon>
      <ListItemText primary="Join Team" />
    </ListItem>
    <ListItem button component={Link} to="/projects">
      <ListItemIcon>
        <NewReleasesIcon />
      </ListItemIcon>
      <ListItemText primary="Projects" />
    </ListItem>
    <ListItem button component={Link} to="/polls">
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="Polls" />
    </ListItem>
    <ListItem button component={Link} to="/srautas">
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="Srautas" />
    </ListItem>
    <ListItem button component={Link} to="/donation">
      <ListItemIcon>
        <MoneyIcon />
      </ListItemIcon>
      <ListItemText primary="Donation" />
    </ListItem>
    <ListItem button component={Link} to="/blacklist">
      <ListItemIcon>
        <MoneyIcon />
      </ListItemIcon>
      <ListItemText primary="Blacklist" />
    </ListItem>
  </div>
);